//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import PropTypes from '@components/PropTypes';
import Icon      from '@stateless/atomic/Icon';

import styles from './styles.module.scss';

const propTypes = {
    active:   PropTypes.bool,
    disabled: PropTypes.bool,
    iconType: PropTypes.any,
    onClick:  PropTypes.func,
    text:     PropTypes.string,
};

const TabBarButton = ({
    iconType = null,
    onClick  = _.noop,
    text     = '',
    disabled = false,
    active   = false,
}) => {
    return (
        <button
            className={classNames(
                styles.tabBarButtonComponent,
                {
                    [styles.active]:   active,
                    [styles.disabled]: disabled,
                },
            )}
            disabled={disabled}
            onClick={onClick}
        >
            <Icon iconType={iconType} />
            {text}
        </button>
    );
};

TabBarButton.propTypes = propTypes;

export default TabBarButton;
