//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import { useMemo } from 'react';

import classNames         from 'classnames';
import _                  from 'lodash';
import moment             from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch }    from 'react-redux';
import { useSelector }    from 'react-redux';

import PropTypes            from '@components/PropTypes';
import DateHelper           from '@helper/Date';
import { DateRangePart }    from '@helper/DateRange';
import DateRangeHelper      from '@helper/DateRange';
import { DateRangeDefault } from '@helper/DateRange';
import { useMapActions }    from '@slices/map';
import IconColor            from '@stateless/atomic/Icon/IconColor';
import IconType             from '@stateless/atomic/Icon/IconType';
import IconButton           from '@stateless/atomic/IconButton';
import IconButtonStyle      from '@stateless/atomic/IconButton/IconButtonStyle';
import SidebarToggleButton  from '@stateless/composed/SidebarToggleButton';
import TimeBeam             from '@stateless/composed/TimeBeam';

import styles from './styles.module.scss';

const propTypes = {
    expanded:               PropTypes.bool,
    onSidebarToggleClicked: PropTypes.func,
};

const SidebarDatePickerContent = ({
    expanded               = false,
    onSidebarToggleClicked = _.noop,
}) => {
    const { t }                         = useTranslation(null, {
        keyPrefix: 'components.datePicker',
    });
    const dispatch                      = useDispatch();
    const mapActions                    = useMapActions(dispatch);
    const selectedDateRange             = useSelector((state) => state.map.selectedDateRange);
    const selectedDateRangeIsSingleDate = useMemo(() => {
        return DateRangeHelper.isSingleDate(selectedDateRange);
    }, [selectedDateRange]);
    const showDateRangePicker           = useSelector((state) => state.map.showDateRangePicker);
    const dayNavigationButtonsDisabled  = useMemo(() => {
        return !selectedDateRangeIsSingleDate;
    }, [showDateRangePicker]);
    const nextDayButtonDisabled         = useMemo(() => {
        return (
            moment(selectedDateRange[DateRangePart.to]).isSame(moment(), 'day') ||
            dayNavigationButtonsDisabled
        );
    }, [selectedDateRange]);

    function onShowDateRangePickerClicked() {
        mapActions.setShowDateRangePicker({
            show: true,
        });
    }

    function onCloseDateRangePickerClicked() {
        mapActions.setShowDateRangePicker({
            show: false,
        });
    }

    function onDateRangeSelectionClicked() {
        if (showDateRangePicker) {
            onCloseDateRangePickerClicked();

            return;
        }

        onShowDateRangePickerClicked();
    }

    function setDateRangeBySingleDate(selection) {
        mapActions.setSelectedDateRange({
            [DateRangePart.from]: moment(selection).toDate(),
            [DateRangePart.to]:   moment(selection).toDate(),
        });
    }

    function onNextDayClicked() {
        const newDate = moment(selectedDateRange[DateRangePart.to]).add(1, 'days').toDate();

        setDateRangeBySingleDate(newDate);
    }

    function onPreviousDayClicked() {
        const newDate = moment(selectedDateRange[DateRangePart.to]).subtract(1, 'days').toDate();

        setDateRangeBySingleDate(newDate);
    }

    function onTodayButtonClicked() {
        setDateRangeBySingleDate(moment().toDate());
    }

    function renderTimeBeam() {
        if (!selectedDateRangeIsSingleDate) {
            return null;
        }

        return (
            <TimeBeam
                selectedDateRange={selectedDateRange}
                setSelectedDate={setDateRangeBySingleDate}
            />
        );
    }

    function onDeleteDateRangeSelectionClicked() {
        mapActions.setSelectedDateRange(DateRangeDefault);
        onCloseDateRangePickerClicked();
    }

    function renderDateRangeSelectionCloseIcon() {
        if (!showDateRangePicker) {
            return null;
        }

        return (
            <IconButton
                iconButtonStyle={IconButtonStyle.transparent}
                iconType={IconType.cross}
                onIconButtonClicked={onDeleteDateRangeSelectionClicked}
            />
        );
    }

    function renderDateRangeSelection() {
        if (selectedDateRangeIsSingleDate) {
            return null;
        }

        return (
            <div
                className={classNames(
                    styles.dateRangeSelection,
                    {
                        [styles.highlighted]: showDateRangePicker,
                    },
                )}
                onClick={onDateRangeSelectionClicked}
            >
                {renderDateRangeSelectionCloseIcon()}
                <label>
                    {t('from')}
                </label>
                <span>
                    {DateHelper.dateToDDMMWithDot(selectedDateRange[DateRangePart.from])}
                </span>
                <label>
                    {t('to')}
                </label>
                <span>
                    {DateHelper.dateToDDMMWithDot(selectedDateRange[DateRangePart.to])}
                </span>
            </div>
        );
    }

    return (
        <div className={styles.sidebarDatePickerComponent}>
            <SidebarToggleButton
                expanded={expanded}
                onSidebarToggleClicked={onSidebarToggleClicked}
            />
            <div className={styles.datePickerContent}>
                {renderTimeBeam()}
                {renderDateRangeSelection()}
                <div className={styles.dateNavigationWrapper}>
                    <IconButton
                        onIconButtonClicked={onPreviousDayClicked}
                        iconType={IconType.chevronUp}
                        iconButtonStyle={IconButtonStyle.blue}
                        disabled={dayNavigationButtonsDisabled}
                    />
                    <button
                        className={styles.todayButton}
                        onClick={onTodayButtonClicked}
                    >
                        {t('today')}
                    </button>
                    <IconButton
                        onIconButtonClicked={onNextDayClicked}
                        iconType={IconType.chevronDown}
                        iconButtonStyle={IconButtonStyle.blue}
                        disabled={nextDayButtonDisabled}
                    />
                </div>
                <IconButton
                    iconButtonStyle={IconButtonStyle.transparent}
                    iconColor={IconColor.darkAbyss}
                    iconType={IconType.calendar}
                    className={styles.dateSelectorButton}
                    onIconButtonClicked={onShowDateRangePickerClicked}
                />
            </div>
        </div>
    );
};

SidebarDatePickerContent.propTypes = propTypes;

export default SidebarDatePickerContent;
