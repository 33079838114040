//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n               from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSelector }    from 'react-redux';
import { useDispatch }    from 'react-redux';
import { Link }           from 'react-router-dom';

import { useUser }   from '@slices/user';
import Headline      from '@stateless/atomic/Headline';
import HeadlineType  from '@stateless/atomic/Headline/HeadlineType';
import Icon          from '@stateless/atomic/Icon';
import IconType      from '@stateless/atomic/Icon/IconType';
import SimpleButton  from '@stateless/atomic/SimpleButton';
import Spacer        from '@stateless/atomic/Spacer';
import TextInput     from '@stateless/atomic/TextInput';
import TextInputType from '@stateless/atomic/TextInput/TextInputType';
import PageTitle     from '@stateless/composed/PageTitle';

import styles from './styles.module.scss';

const Login = () => {
    const dispatch    = useDispatch();
    const userActions = useUser(dispatch);
    const username    = useSelector((state) => state.user.username);
    const password    = useSelector((state) => state.user.password);
    const { t }       = useTranslation(null, {
        keyPrefix: 'components.login',
    });

    function onAutoLoginButtonClicked(event) {
        event.preventDefault();

        const defaultUsername = 'info@lulububu.de';
        const defaultPassword = 'lulububu';

        userActions.loginWithCredentials({
            username: defaultUsername,
            password: defaultPassword,
        });
    }

    function onFormSubmit(event) {
        event.preventDefault();
        userActions.login();
    }

    function passwordChanged(event) {
        userActions.setPassword({
            password: event.target.value,
        });
    }

    function usernameChanged(event) {
        userActions.setUsername({
            username: event.target.value,
        });
    }

    function renderAutoLoginButton() {
        if (process.env.NODE_ENV !== 'development') {
            return null;
        }

        return (
            <SimpleButton
                text={t('autoLogin')}
                onClick={onAutoLoginButtonClicked}
            />
        );
    }

    return (
        <>
            <PageTitle title={I18n.t('pageLogin')} />
            <div className={styles.loginScreen}>
                <form onSubmit={onFormSubmit}>
                    <Icon iconType={IconType.logo} />
                    <Headline
                        title={t('login')}
                        type={HeadlineType.headline1}
                    />
                    <div className={styles.inputWrapper}>
                        <TextInput
                            type={TextInputType.email}
                            postIconType={IconType.mail}
                            onChange={usernameChanged}
                            value={username}
                            placeholderText={t('email')}
                        />
                        <Spacer height={10} />
                        <TextInput
                            type={TextInputType.password}
                            postIconType={IconType.lock}
                            onChange={passwordChanged}
                            value={password}
                            placeholderText={t('password')}
                        />
                    </div>
                    <SimpleButton
                        text={t('login')}
                    />
                    {renderAutoLoginButton()}
                    <div className={styles.forgotPasswordWrapper}>
                        <span>
                            {t('passwordForgotten')}
                        </span>
                        <Link to="/password-reset">
                            {t('newPassword')}
                        </Link>
                    </div>
                </form>
                <a href={I18n.t('imprintUrl')}>
                    {t('imprint')}
                </a>
            </div>
        </>
    );
};

export default Login;
