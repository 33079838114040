//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import { useMemo } from 'react';

import classNames      from 'classnames';
import _               from 'lodash';
import { useSelector } from 'react-redux';

import ContextInformationTab     from '@connected/ContextInformationPanel/ContextInformationTab';
import ContextType               from '@constants/ContextType';
import useContextApi             from '@hooks/useContextApi';
import useContextLoader          from '@hooks/useContextLoader';
import IconType                  from '@stateless/atomic/Icon/IconType';
import IconButton                from '@stateless/atomic/IconButton';
import ContextInformationContent from '@stateless/composed/ContextInformationContent';
import ContextInformationHeader  from '@stateless/composed/ContextInformationHeader';
import ContextInformationTabBar  from '@stateless/composed/ContextInformationTabBar';

import styles from './styles.module.scss';

const ContextInformationPanel = () => {
    const showQuickViewSidebarDetails                     = useSelector((state) => state.quickView.showQuickViewSidebarDetails);
    const showMapSearchSidebar                            = useSelector((state) => state.map.showMapSearchSidebar);
    const { setActiveTab, clearContext, selectedContext } = useContextLoader();
    const activeTab                                       = _.get(selectedContext, 'activeTab', ContextInformationTab.overview);
    const { data, isFetching }                            = useContextApi();
    const showContextInformationPanel                     = useMemo(() => {
        const mapSearchSidebarDetailsAreExpanded = (
            showMapSearchSidebar &&
            showQuickViewSidebarDetails
        );

        return (
            selectedContext?.id &&
            !mapSearchSidebarDetailsAreExpanded
        );
    }, [selectedContext, showQuickViewSidebarDetails, showMapSearchSidebar]);
    const createOnTabButtonClicked                        = (tab) => () => {
        setActiveTab(tab);
    };

    function unselectContextClicked() {
        clearContext();
    }

    function closeActiveTabClicked() {
        setActiveTab(null);
    }

    function renderCloseActiveTabRow() {
        if (activeTab) {
            return (
                <div
                    className={styles.closeActiveTabRow}
                >
                    <IconButton
                        onIconButtonClicked={closeActiveTabClicked}
                        iconType={IconType.chevronDown}
                    />
                </div>
            );
        }

        return null;
    }

    function renderContextInformationHeader() {
        return (
            <ContextInformationHeader
                context={data?.context}
                type={selectedContext?.type}
                isLoading={isFetching}
                onCloseClicked={unselectContextClicked}
            />
        );
    }

    function renderContextPanelContent() {
        if (selectedContext?.type === ContextType.route) {
            return renderContextInformationHeader();
        }

        return (
            <>
                {renderCloseActiveTabRow()}
                {renderContextInformationHeader()}
                <ContextInformationContent
                    activeTab={activeTab}
                    isLoading={isFetching}
                    context={data?.context}
                />
                <ContextInformationTabBar
                    activeTab={activeTab}
                    createOnTabClicked={createOnTabButtonClicked}
                />
            </>
        );
    }

    return (
        <div
            className={classNames(
                styles.contextInformationPanelComponent,
                {
                    [styles.open]: showContextInformationPanel,
                },
            )}
        >
            {renderContextPanelContent()}
        </div>
    );
};

export default ContextInformationPanel;
