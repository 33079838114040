//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    height: PropTypes.number,
    width:  PropTypes.number,
};

const Spacer = ({
    height = 0,
    width  = 0,
}) => {
    function getStyle() {
        const style = {};

        if (height) {
            style.height = height;
        }

        if (width) {
            style.width = width;
        }

        return style;
    }

    const style = getStyle();

    return (
        <span
            className={styles.spacer}
            style={style}
        />
    );
};

Spacer.propTypes = propTypes;

export default Spacer;
