//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes       from '@components/PropTypes';
import CircleIcon      from '@stateless/atomic/CircleIcon';
import CircleIconSize  from '@stateless/atomic/CircleIcon/CircleIconSize';
import CircleIconTheme from '@stateless/atomic/CircleIcon/CircleIconTheme';

import styles from './styles.module.scss';

const propTypes = {
    circleIconSize: PropTypes.oneOfObjectKeys(CircleIconSize),
    circleTheme:    PropTypes.oneOfObjectKeys(CircleIconTheme),
    decoration:     PropTypes.children,
    iconType:       PropTypes.iconType,
    text:           PropTypes.any,
};

const MapPin = ({
    iconType       = null,
    text           = '',
    decoration     = null,
    circleIconSize = CircleIconSize.default,
    circleTheme    = CircleIconTheme.transparent,
}) => {
    return (
        <div className={styles.mapPinPlacer}>
            <button className={styles.mapPinWrapper}>
                <CircleIcon
                    size={circleIconSize}
                    iconType={iconType}
                    circleIconTheme={circleTheme}
                    text={text}
                />
            </button>
            {decoration}
        </div>
    );
};

MapPin.propTypes = propTypes;

export default MapPin;
