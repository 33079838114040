//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useTranslation } from 'react-i18next';

import ContextType              from '@constants/ContextType';
import useContextLoader         from '@hooks/useContextLoader';
import useStopSearchFormControl from '@hooks/useStopSearchFormControl';
import SimpleButton             from '@stateless/atomic/SimpleButton';
import StopSearchInput          from '@stateless/composed/StopSearchInput';

const SidebarStopSearchForm = () => {
    const {
        executeSearchStop,
        onStopSearchQueryChanged,
        displayStopResults,
        stopSearchQuery,
        hideStopResults,
        showStopResults,
        isFetchingStops,
        stopResults,
    }                 = useStopSearchFormControl();
    const { t }             = useTranslation(null, {
        keyPrefix: 'components.search',
    });
    const { loadInContext } = useContextLoader();

    function onStopSearchFormBlurred(event) {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            hideStopResults();
        }
    }

    function onStopResultClicked(stop) {
        return () => {
            loadInContext({
                id:   stop.id,
                type: ContextType.stop,
            });
            hideStopResults();
        };
    }

    function onFocusGain() {
        if (stopSearchQuery) {
            showStopResults();
        }
    }

    function onStopSearchButtonClicked() {
        executeSearchStop();
    }

    return (
        <div
            onBlur={onStopSearchFormBlurred}
        >
            <StopSearchInput
                value={stopSearchQuery}
                onInputChanges={onStopSearchQueryChanged}
                labelText={t('searchStopLabel')}
                searchResults={stopResults}
                onResultClicked={onStopResultClicked}
                showResults={displayStopResults}
                isLoading={isFetchingStops}
                onSubmit={executeSearchStop}
                onFocusGain={onFocusGain}
            />
            <SimpleButton
                text={t('searchStopButtonText')}
                onClick={onStopSearchButtonClicked}
            />
        </div>
    );
};

export default SidebarStopSearchForm;
