//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useCallback } from 'react';
import { useEffect }   from 'react';

import * as turf    from '@turf/turf';
import * as Leaflet from 'leaflet';
import _            from 'lodash';
import { useMap }   from 'react-leaflet';

import PropTypes                 from '@components/PropTypes';
import ContextType               from '@constants/ContextType';
import PolylineLevel             from '@constants/PolylineLevel';
import useContextLoader          from '@hooks/useContextLoader';
import { useFetchPolylineQuery } from '@store/api/polyline';

// eslint-disable-next-line rulesdir/format-import-linter, import/extensions
import 'leaflet.vectorgrid/dist/Leaflet.VectorGrid.bundled.js';

const propTypes = {
    fetchByRouteId:     PropTypes.bool,
    ignoreMinZoomLevel: PropTypes.bool,
    level:              PropTypes.oneOfObjectValues(PolylineLevel),
    polylineData:       PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    route:              PropTypes.objectOf(PropTypes.any),
};

const MapRouteLayer = ({
    route              = null,
    level              = PolylineLevel.low,
    ignoreMinZoomLevel = false,
    polylineData       = null,
    fetchByRouteId     = false,
}) => {
    const map               = useMap();
    const { loadInContext } = useContextLoader();
    const { data }          = useFetchPolylineQuery(
        {
            routeId: route.id,
            level,
        },
        {
            skip: !fetchByRouteId,
        },
    );
    const dataToDisplay     = fetchByRouteId
        ? data
        : polylineData;
    const onRouteClicked    = useCallback(() => {
        loadInContext({
            id:   route.id,
            type: ContextType.route,
        });
    }, [route]);

    useEffect(
        () => {
            if (
                !dataToDisplay ||
                !dataToDisplay.length
            ) {
                return _.noop;
            }

            const lineStrings       = turf.lineStrings(dataToDisplay);
            const vectorGridOptions = {
                rendererFactory:       Leaflet.svg.tile,
                vectorTileLayerStyles: {
                    sliced(properties, zoom) {
                        return {
                            stroke: true,
                            color:  route.color ?? 'lime',
                            weight: 5,
                        };
                    },
                },
                interactive:           true,
                maxZoom:               20,
            };

            if (!ignoreMinZoomLevel) {
                vectorGridOptions.minZoom = 14;
            }

            const vectorGrid = Leaflet.vectorGrid.slicer(lineStrings, vectorGridOptions);

            vectorGrid
                .on('click', onRouteClicked)
                .addTo(map);

            return () => {
                map.removeLayer(vectorGrid);
            };
        },
        [dataToDisplay],
    );

    return null;
};

MapRouteLayer.propTypes = propTypes;

export default MapRouteLayer;
