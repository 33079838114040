//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n           from 'i18next';
import { useHistory } from 'react-router';

import Routes       from '@constants/Routes';
import Headline     from '@stateless/atomic/Headline';
import HeadlineType from '@stateless/atomic/Headline/HeadlineType';
import Icon         from '@stateless/atomic/Icon';
import IconType     from '@stateless/atomic/Icon/IconType';
import SimpleButton from '@stateless/atomic/SimpleButton';
import PageTitle    from '@stateless/composed/PageTitle';

import styles from './styles.module.scss';

const NotFound = () => {
    const history             = useHistory();
    const onBackButtonClicked = () => {
        history.push(Routes.home);
    };

    return (
        <>
            <PageTitle
                title={I18n.t('pageNotFound')}
            />
            <div className={styles.notFoundWrapper}>
                <div className={styles.contentWrapper}>
                    <Icon iconType={IconType.logo} />
                    <Headline
                        title={I18n.t('error_404')}
                        type={HeadlineType.headline1}
                    />
                    <p>
                        {I18n.t('notFoundText')}
                    </p>
                    <SimpleButton
                        text={I18n.t('backToMap')}
                        onClick={onBackButtonClicked}
                    />
                </div>
            </div>
        </>
    );
};

export default NotFound;
