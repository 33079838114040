//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useEffect } from 'react';

import { useSelector }   from 'react-redux';
import { useDispatch }   from 'react-redux';
import { useQueryParam } from 'use-query-params';
import { StringParam }   from 'use-query-params';

import DateRangeHelper   from '@helper/DateRange';
import useContextLoader  from '@hooks/useContextLoader';
import { useMapActions } from '@slices/map';

const useStateQueryParameter = () => {
    const dispatch                           = useDispatch();
    const mapActions                         = useMapActions(dispatch);
    const { loadInContext, selectedContext } = useContextLoader();
    const selectedDateRange                  = useSelector((state) => state.map.selectedDateRange);
    const [contextState, setContextState]    = useQueryParam('state', StringParam);

    // --------------------------------------------------
    // set query parameter on redux state change
    // --------------------------------------------------
    useEffect(() => {
        const encodedState = window.btoa(JSON.stringify(selectedContext));

        setContextState(encodedState);
    }, [selectedContext, selectedDateRange]);

    // --------------------------------------------------
    // get redux state from query parameter on mount
    // --------------------------------------------------
    useEffect(() => {
        if (!contextState) {
            return;
        }

        const decodedState = JSON.parse(window.atob(contextState));

        if (!decodedState) {
            return;
        }

        const dateRange = decodedState.dateRange;

        loadInContext(decodedState);

        if (!DateRangeHelper.areDateRangesEqual(dateRange, selectedDateRange)) {
            mapActions.setSelectedDateRange(dateRange);
        }
    }, []);

    return null;
};

export default useStateQueryParameter;
