//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import classNames         from 'classnames';
import _                  from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector }    from 'react-redux';
import { useDispatch }    from 'react-redux';

import ContextType               from '@constants/ContextType';
import useContextLoader          from '@hooks/useContextLoader';
import useRouteSearchFormControl from '@hooks/useRouteSearchFormControl';
import { useSearchActions }      from '@slices/search';
import IconType                  from '@stateless/atomic/Icon/IconType';
import SidebarConnectionItem     from '@stateless/atomic/SidebarConnectionItem';
import SidebarHeader             from '@stateless/atomic/SidebarHeader';
import SkeletonItem              from '@stateless/atomic/SkeletonItem';
import SkeletonItemType          from '@stateless/atomic/SkeletonItem/SkeletonItemType';
import Spacer                    from '@stateless/atomic/Spacer';
import SidebarConnectionHeader   from '@stateless/composed/SidebarConnectionHeader';

import styles from './styles.module.scss';

const SidebarRouteSearchResultsOverlay = () => {
    const { t }                                                                = useTranslation(null, {
        keyPrefix: 'components.search',
    });
    const dispatch                                                             = useDispatch();
    const searchActions                                                        = useSearchActions(dispatch);
    const showRouteSearchResults                                               = useSelector((state) => state.search.showRouteSearchResults);
    const routeFromStop                                                        = useSelector((state) => state.search.routeFromStop);
    const routeToStop                                                          = useSelector((state) => state.search.routeToStop);
    const { routesResult, isFetchingRoutes, routesError, executeRoutesSearch } = useRouteSearchFormControl();
    const { loadInContext }                                                    = useContextLoader();

    function onBackButtonClicked() {
        searchActions.setShowRouteSearchResults({
            showRouteSearchResults: false,
        });
    }

    useEffect(() => {
        if (showRouteSearchResults) {
            executeRoutesSearch();
        }
    }, [showRouteSearchResults]);

    function renderLoadingItems() {
        return _.times(4, (index) => {
            const key = `skeletonItem-${index}`;

            return (
                <SkeletonItem
                    key={key}
                    type={SkeletonItemType.connectionItem}
                />
            );
        });
    }

    const renderNoResults    = () => {
        return (
            <div className={styles.textContent}>
                <p>
                    {t('noResults')}
                </p>
            </div>

        );
    };
    const renderErrorMessage = () => {
        return (
            <div className={styles.textContent}>
                <p>
                    {t('error')}
                </p>
            </div>
        );
    };

    function renderConnectionItems() {
        if (isFetchingRoutes) {
            return renderLoadingItems();
        }

        if (_.isEmpty(routesResult)) {
            return renderNoResults();
        }

        if (routesError) {
            return renderErrorMessage();
        }

        return _.map(routesResult, (route, index) => {
            const { id, shortName, color } = route;
            const onRouteClicked           = () => {
                loadInContext({
                    id,
                    type: ContextType.route,
                });
            };

            return (
                <SidebarConnectionItem
                    key={id}
                    fromStopName={routeFromStop?.name}
                    toStopName={routeToStop?.name}
                    routes={[
                        {
                            percentageWidth: 100,
                            name:            shortName ?? t('unknown'),
                            color,
                            disabled:        id,
                            onRouteClicked,
                        },
                    ]}
                />
            );
        });
    }

    return (
        <div
            className={classNames(
                styles.routeResultsContentComponent,
                {
                    [styles.visible]: showRouteSearchResults,
                },
            )}
        >
            <div className={styles.contentWrapper}>
                <SidebarHeader
                    iconType={IconType.chevronLeft}
                    headline={t('overview')}
                    onIconClicked={onBackButtonClicked}
                />
                <Spacer height={50} />
                <SidebarConnectionHeader
                    routeFromStopName={routeFromStop?.name}
                    routeToStopName={routeToStop?.name}
                />
                {renderConnectionItems()}
            </div>
        </div>
    );
};

export default SidebarRouteSearchResultsOverlay;
