//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createApi }      from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import ApiUrls        from '@constants/ApiUrls';
import DurationHelper from '@helper/DurationHelper';
import Url            from '@helper/Url';

export const connectionsViewApi               = createApi({
    reducerPath: 'connectionsViewApi',
    baseQuery:   fetchBaseQuery({
        baseUrl: Url.createEndpoint(ApiUrls.API_CONNECTIONS_VIEW),
    }),
    endpoints:   (builder) => (
        {
            fetchConnectionsView: builder.query({
                query:             ({ view, dateRange, target }) => (
                    {
                        url:    '',
                        params: {
                            view,
                            dateRange,
                            target,
                        },
                    }
                ),
                keepUnusedDataFor: DurationHelper.daysToSeconds(1),
            }),
        }
    ),
});
export const { useFetchConnectionsViewQuery } = connectionsViewApi;
export const ConnectionsViewApiReducer        = connectionsViewApi.reducer;

export const ConnectionsViewApiReducerPath = connectionsViewApi.reducerPath;

export const ConnectionsViewApiMiddleware = connectionsViewApi.middleware;

export default connectionsViewApi;
