//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch }    from 'react-redux';
import { useSelector }    from 'react-redux';

import MapFilter                   from '@constants/MapFilter';
import FilterHelper                from '@helper/FilterHelper';
import { useMapActions }           from '@slices/map';
import IconType                    from '@stateless/atomic/Icon/IconType';
import SidebarSeparator            from '@stateless/composed/SidebarSeparator';
import SidebarSwitchToggleListItem from '@stateless/composed/SidebarSwitchToggleListItem';

const propTypes = {};

const SidebarInformationList = () => {
    const filter          = useSelector((state) => state.map.filter) ?? [];
    const dispatch        = useDispatch();
    const mapAction       = useMapActions(dispatch);
    const { t }           = useTranslation(null, {
        keyPrefix: 'components.sidebar',
    });
    const toggleValue     = (key) => (value) => {
        let newFilters = [];

        if (value) {
            newFilters = [...filter, key];
        } else {
            newFilters = filter.filter((item) => item !== key);
        }

        mapAction.setFilter(newFilters);
    };
    const visibilityItems = [
        {
            title:           t(MapFilter.lines),
            iconType:        IconType.line,
            checked:         FilterHelper.filterIsSet(filter, MapFilter.lines),
            onToggleClicked: toggleValue(MapFilter.lines),
            disabled:        false,
        },
        {
            title:           t(MapFilter.busStops),
            iconType:        IconType.bus,
            checked:         FilterHelper.filterIsSet(filter, MapFilter.busStops),
            onToggleClicked: toggleValue(MapFilter.busStops),
            disabled:        false,
        },
        {
            title:           t(MapFilter.trainStations),
            iconType:        IconType.train,
            checked:         FilterHelper.filterIsSet(filter, MapFilter.trainStations),
            onToggleClicked: toggleValue(MapFilter.trainStations),
            disabled:        false,
        },
        {
            title:           t(MapFilter.ferryStations),
            iconType:        IconType.ferry,
            checked:         FilterHelper.filterIsSet(filter, MapFilter.ferryStations),
            onToggleClicked: toggleValue(MapFilter.ferryStations),
            disabled:        false,
        },
        {
            title:           t('passengerVolume'),
            iconType:        IconType.passengerVolume,
            checked:         FilterHelper.filterIsSet(filter, 'passengerVolume'),
            onToggleClicked: toggleValue('passengerVolume'),
            disabled:        true,
        },
        {
            title:           t('disturbances'),
            iconType:        IconType.warning,
            checked:         FilterHelper.filterIsSet(filter, 'disturbances'),
            onToggleClicked: toggleValue('disturbances'),
            disabled:        true,
        },
        {
            title:           t('constructionSites'),
            iconType:        IconType.construction,
            checked:         FilterHelper.filterIsSet(filter, 'constructionSites'),
            onToggleClicked: toggleValue('constructionSites'),
            disabled:        true,
        },
        {
            title:           t('delays'),
            iconType:        IconType.punctuality,
            checked:         FilterHelper.filterIsSet(filter, 'delays'),
            onToggleClicked: toggleValue('delays'),
            disabled:        true,
        },
        {
            title:           t('connectionNotReachable'),
            iconType:        IconType.standOut,
            checked:         FilterHelper.filterIsSet(filter, 'connectionNotReachable'),
            onToggleClicked: toggleValue('connectionNotReachable'),
            disabled:        true,
        },
        {
            title:           t('connectionCombinations'),
            iconType:        IconType.connections,
            checked:         FilterHelper.filterIsSet(filter, 'connectionCombinations'),
            onToggleClicked: toggleValue('connectionCombinations'),
            disabled:        true,
        },
        {
            title:           t('weather'),
            iconType:        IconType.cloud,
            checked:         FilterHelper.filterIsSet(filter, 'weather'),
            onToggleClicked: toggleValue('weather'),
            disabled:        true,
        },
    ];

    function renderVisibilityItems() {
        return visibilityItems.map((item) => {
            const { title, iconType, checked, onToggleClicked, disabled } = item;

            return (
                <SidebarSwitchToggleListItem
                    key={title}
                    iconType={iconType}
                    title={title}
                    checked={checked}
                    onToggleClicked={onToggleClicked}
                    disabled={disabled}
                />
            );
        });
    }

    return (
        <div>
            <SidebarSeparator text={t('information')} />
            {renderVisibilityItems()}
        </div>
    );
};

SidebarInformationList.propTypes = propTypes;

export default SidebarInformationList;
