//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import moment from 'moment';

import DateHelper from '@helper/Date';

export const DateRangePart = Object.freeze({
    from: 'from',
    to:   'to',
});

export const DateRangeDefault = {
    [DateRangePart.from]: moment().subtract(6, 'months').startOf('month').toDate(),
    [DateRangePart.to]:   moment().endOf('month').toDate(),
};

export default class DateRangeHelper {
    static getRangeString(dateRange) {
        const fallbackDateRange = {
            ...DateRangeDefault,
            ...dateRange,
        };
        const fromPart          = DateHelper.dateToYYYYMMDD(fallbackDateRange[DateRangePart.from]);
        const toPart            = DateHelper.dateToYYYYMMDD(fallbackDateRange[DateRangePart.to]);

        return `${fromPart}-${toPart}`;
    }

    static isSingleDate(dateRange) {
        const fromDate = DateHelper.dateToYYYYMMDDWithStroke(dateRange.from);
        const toDate   = DateHelper.dateToYYYYMMDDWithStroke(dateRange.to);

        return fromDate === toDate;
    }

    static getPrettyRangeString(dateRange) {
        const fallbackDateRange = {
            ...DateRangeDefault,
            ...dateRange,
        };
        const fromPart          = moment(fallbackDateRange[DateRangePart.from]).format('DD.MM.YYYY');
        const toPart            = moment(fallbackDateRange[DateRangePart.to]).format('DD.MM.YYYY');

        if (fromPart === toPart) {
            return fromPart;
        }

        return `${fromPart} - ${toPart}`;
    }

    static getDateRangeNumberOfDays(dateRange) {
        const fallbackDateRange = {
            ...DateRangeDefault,
            ...dateRange,
        };
        const fromPart          = moment(fallbackDateRange[DateRangePart.from]);
        const toPart            = moment(fallbackDateRange[DateRangePart.to]);

        return toPart.diff(fromPart, 'days') + 1;
    }

    static areDateRangesEqual(dateRange1, dateRange2) {
        const from1 = DateHelper.dateToYYYYMMDD(dateRange1.from);
        const to1   = DateHelper.dateToYYYYMMDD(dateRange1.to);
        const from2 = DateHelper.dateToYYYYMMDD(dateRange2.from);
        const to2   = DateHelper.dateToYYYYMMDD(dateRange2.to);

        return from1 === from2 && to1 === to2;
    }
}
