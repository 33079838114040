//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const InitialZoom = 15;

const MinStopZoom = InitialZoom;

const ShowRoutesHintZoom = 14;

export default {
    InitialZoom,
    MinStopZoom,
    ShowRoutesHintZoom,
};
