//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useDispatch } from 'react-redux';

import { useMapActions } from '@slices/map';

const useMapAccessor = () => {
    const dispatch                    = useDispatch();
    const { setZoom, setCoordinates } = useMapActions(dispatch);

    function getBounds(map) {
        const bounds = map.getBounds();

        return [
            bounds.getWest(),
            bounds.getSouth(),
            bounds.getEast(),
            bounds.getNorth(),
        ];
    }

    function updateCoordinates(map) {
        const { lat, lng } = map.getCenter();

        setCoordinates({
            latitude:  lat,
            longitude: lng,
            bounds:    getBounds(map),
        });
    }

    function updateZoom(map) {
        setZoom({
            zoom:   map.getZoom(),
            bounds: getBounds(map),
        });
    }

    return {
        updateCoordinates,
        updateZoom,
    };
};

export default useMapAccessor;
