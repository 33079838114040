//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames      from 'classnames';
import I18n            from 'i18next';
import _               from 'lodash';
import { useSelector } from 'react-redux';

import ContextType      from '@constants/ContextType';
import ContextHelper    from '@helper/ContextHelper';
import DateRangeHelper  from '@helper/DateRange';
import useContextLoader from '@hooks/useContextLoader';
import CircleIcon       from '@stateless/atomic/CircleIcon';
import CircleIconTheme  from '@stateless/atomic/CircleIcon/CircleIconTheme';
import IconType         from '@stateless/atomic/Icon/IconType';
import ShortNameLabel   from '@stateless/atomic/ShortNameLabel';

import styles from './styles.module.scss';

const propTypes = {};

const HistoryAccessor = () => {
    const historyItems           = useSelector((state) => state.history.contextHistory);
    const { loadInContext }      = useContextLoader();
    const renderDateRangeString  = (dateRange) => {
        return (
            <span className={styles.dateLabel}>
                {DateRangeHelper.getPrettyRangeString(dateRange)}
            </span>
        );
    };
    const renderRouteHistoryItem = (item) => {
        return (
            <>
                <CircleIcon
                    iconType={IconType.line}
                    circleIconTheme={CircleIconTheme.darkAbyss}
                />
                <ShortNameLabel
                    text={item.name}
                />
                <div className={styles.historyItemContent}>
                    <span>
                        {I18n.t('line')}
                    </span>
                    {renderDateRangeString(item.dateRange)}
                </div>
            </>
        );
    };
    const renderStopHistoryItem  = (item) => {
        return (
            <>
                <CircleIcon
                    iconType={ContextHelper.getIconTypeByStopType(item.stopType)}
                    circleIconTheme={CircleIconTheme.darkAbyss}
                />
                <div className={styles.historyItemContent}>
                    <span>
                        {item.name}
                    </span>
                    {renderDateRangeString(item.dateRange)}
                </div>
            </>
        );
    };
    const renderItems            = [
        {
            type:   ContextType.route,
            render: renderRouteHistoryItem,
        },
        {
            type:   ContextType.stop,
            render: renderStopHistoryItem,
        },
    ];
    const onHistoryItemClick     = (item) => () => {
        loadInContext({
            ...item,
        });
    };
    const renderHistoryItems     = () => _.map(historyItems, (item) => {
        const historyRenderItem = renderItems.find((renderItem) => renderItem.type === item.type);
        const itemName          = item.name;

        if (
            !historyRenderItem ||
            !itemName
        ) {
            return null;
        }

        return (
            <button
                onClick={onHistoryItemClick(item)}
                key={item.hash}
                className={classNames(
                    styles.historyItem,
                    {
                        [styles.active]: item.isActive,
                    },
                )}
            >
                {historyRenderItem.render(item)}
            </button>
        );
    });

    return (
        <div className={styles.historyAccessor}>
            {renderHistoryItems()}
        </div>
    );
};

HistoryAccessor.propTypes = propTypes;

export default HistoryAccessor;
