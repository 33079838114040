//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import { useSelector } from 'react-redux';

import DateRangeHelper from '@helper/DateRange';

import styles from './styles.module.scss';

const SelectedDateRangeLabel = () => {
    const selectedDateRange = useSelector((state) => state.map.selectedDateRange);

    return (
        <span className={styles.dateString}>
            {DateRangeHelper.getPrettyRangeString(selectedDateRange)}
        </span>
    );
};

export default SelectedDateRangeLabel;
