//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useSelector } from 'react-redux';

import DateRangeHelper          from '@helper/DateRange';
import useContextHistory        from '@hooks/useContextHistory';
import useContextLoader         from '@hooks/useContextLoader';
import { useFetchContextQuery } from '@store/api/context';

const useContextApi = () => {
    const { selectedContext }                    = useContextLoader();
    const selectedDateRange                      = useSelector((state) => state.map.selectedDateRange);
    const dateRangeString                        = DateRangeHelper.getRangeString(selectedDateRange);
    const isContextSelected                      = !!selectedContext?.id;
    const { addToHistory }                       = useContextHistory();
    const options                                = {
        id:        selectedContext?.id,
        type:      selectedContext?.type,
        dateRange: dateRangeString,
    };
    const { data, error, isLoading, isFetching } = useFetchContextQuery(options, {
        skip: !isContextSelected,
    });

    if (
        !isLoading &&
        !isFetching &&
        isContextSelected &&
        data
    ) {
        addToHistory(data, selectedDateRange);
    }

    return {
        data,
        error,
        isLoading,
        isFetching,
    };
};

export default useContextApi;
