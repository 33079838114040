//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _      from 'lodash';
import Switch from 'react-switch';

import PropTypes from '@components/PropTypes';
import Colors    from '@styles/colors.scss';

const propTypes = {
    checked:         PropTypes.bool,
    disabled:        PropTypes.bool,
    onToggleClicked: PropTypes.func,
};

const SwitchToggle = ({
    checked         = false,
    disabled        = false,
    onToggleClicked = _.noop,
}) => {
    return (
        <label>
            <Switch
                checked={checked}
                disabled={disabled}
                onChange={onToggleClicked}
                onColor={Colors.darkAbyss}
                offColor={Colors.darkerSnow}
                uncheckedIcon={false}
                checkedIcon={false}
                handleDiameter={24}
            />
        </label>
    );
};

SwitchToggle.propTypes = propTypes;

export default SwitchToggle;
