//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _               from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import PropTypes                        from '@components/PropTypes';
import QuickViewSidebarDetails          from '@connected/QuickViewSidebarDetails';
import SidebarMapSearchContent          from '@connected/SidebarMapSearchContent';
import SidebarRouteSearchResultsOverlay from '@connected/SidebarRouteSearchResultsContent';
import { useQuickViewActions }          from '@slices/quickView';
import Sidebar                          from '@stateless/composed/Sidebar';
import SidebarPosition                  from '@stateless/composed/Sidebar/SidebarPosition';
import SidebarType                      from '@stateless/composed/Sidebar/SidebarType';

const propTypes = {
    expanded:               PropTypes.bool,
    onSidebarToggleClicked: PropTypes.func,
};

const MapSearchSidebar = ({
    expanded               = false,
    onSidebarToggleClicked = _.noop,
}) => {
    const showQuickViewSidebarDetails = useSelector((state) => state.quickView.showQuickViewSidebarDetails);
    const dispatch                    = useDispatch();
    const QuickViewActions            = useQuickViewActions(dispatch);

    function onCloseQuickViewSidebarClicked() {
        QuickViewActions.setShowQuickViewSidebarDetails({
            showQuickViewSidebarDetails: false,
        });
    }

    function onFullSidebarToggleClicked() {
        onSidebarToggleClicked();
        onCloseQuickViewSidebarClicked();
    }

    return (
        <Sidebar
            expanded={expanded}
            onSidebarToggleClicked={onFullSidebarToggleClicked}
            position={SidebarPosition.left}
            type={SidebarType.fullyCollapsed}
            detailSidebarContent={<QuickViewSidebarDetails />}
            showDetailSidebar={showQuickViewSidebarDetails}
            onDetailSidebarCloseClicked={onCloseQuickViewSidebarClicked}
        >
            <SidebarMapSearchContent
                expanded={expanded}
                onSidebarToggleClicked={onFullSidebarToggleClicked}
            />
            <SidebarRouteSearchResultsOverlay />
        </Sidebar>
    );
};

MapSearchSidebar.propTypes = propTypes;

export default MapSearchSidebar;
