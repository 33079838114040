//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    content: PropTypes.children,
    label:   PropTypes.string,
};

const SidebarLabeledItem = ({
    label   = '',
    content = null,
}) => {
    return (
        <div className={styles.sidebarLabeledTextItem}>
            <label>
                {label}
            </label>
            {content}
        </div>
    );
};

SidebarLabeledItem.propTypes = propTypes;

export default SidebarLabeledItem;
