//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

function* scrollToTop() {
    window.scrollTo(0, 0);
}

function* scrollToTopReactDataGrid() {
    const firstTable = document.querySelector('.react-grid-Canvas');

    if (firstTable) {
        // Scroll the first (and only) visible table up
        firstTable.scrollTo({
            top:      0,
            left:     0,
            behavior: 'smooth',
        });
    }
}

export default {
    scrollToTop,
    scrollToTopReactDataGrid,
};
