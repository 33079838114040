//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useMap } from 'react-leaflet';

import WindowHelper    from '@helper/WindowHelper';
import IconType        from '@stateless/atomic/Icon/IconType';
import IconButton      from '@stateless/atomic/IconButton';
import IconButtonStyle from '@stateless/atomic/IconButton/IconButtonStyle';

import styles from './styles.module.scss';

const propTypes = {};

const MapControlLayer = () => {
    const map = useMap();

    function onZoomInClicked(event) {
        event.stopPropagation();
        map.zoomIn();
    }

    function onZoomOutClicked(event) {
        event.stopPropagation();
        map.zoomOut();
    }

    function onFullScreenClicked(event) {
        event.stopPropagation();
        WindowHelper.toggleFullscreen();
    }

    return (
        <div className={styles.mapControlButtonsContainer}>
            <div className={styles.zoomControlButtonContainer}>
                <IconButton
                    iconButtonStyle={IconButtonStyle.blue}
                    iconType={IconType.plus}
                    noBottomBorderRadius={true}
                    onIconButtonClicked={onZoomInClicked}
                />
                <IconButton
                    iconButtonStyle={IconButtonStyle.blue}
                    iconType={IconType.minus}
                    noTopBorderRadius={true}
                    onIconButtonClicked={onZoomOutClicked}
                />
            </div>
            <IconButton
                iconButtonStyle={IconButtonStyle.blue}
                iconType={IconType.fullscreen}
                onIconButtonClicked={onFullScreenClicked}
            />
        </div>
    );
};

MapControlLayer.propTypes = propTypes;

export default MapControlLayer;
