//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useRef }    from 'react';
import { useEffect } from 'react';

import Tippy      from '@tippyjs/react';
import classNames from 'classnames';
import I18n       from 'i18next';

import PropTypes            from '@components/PropTypes';
import ConnectionDetailType from '@constants/ConnectionDetailType';
import Icon                 from '@stateless/atomic/Icon';
import IconColor            from '@stateless/atomic/Icon/IconColor';
import IconType             from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {
    changeIconWidth:      PropTypes.number,
    connectionDetailType: PropTypes.oneOfObjectValues(ConnectionDetailType),
    fixedWidth:           PropTypes.number,
    isLastRoute:          PropTypes.bool,
    route:                PropTypes.object,
    showWithDetails:      PropTypes.bool,
};

const SidebarConnectionChange = ({
    route                = null,
    isLastRoute          = false,
    fixedWidth           = 0,
    showWithDetails      = false,
    connectionDetailType = null,
    changeIconWidth      = 0,
}) => {
    const [shouldTooltipBeShown, setShouldTooltipBeShown]                = useState(false);
    const textWrapperRef                                                 = useRef();
    const { changeStopName, changeDetailNumber, changeDetailRealNumber } = route;
    const changeIconStyle                                                = {
        width: changeIconWidth,
    };
    const changeStopNameStyle                                            = {
        width: fixedWidth,
    };

    useEffect(() => {
        if (!textWrapperRef.current) {
            return;
        }

        setShouldTooltipBeShown(textWrapperRef.current.scrollWidth > textWrapperRef.current.clientWidth);
    }, [textWrapperRef]);

    function renderRouteRealNumber(number, realNumber, postfix) {
        if (
            !realNumber ||
            number === realNumber
        ) {
            return null;
        }

        const differenceIsPositive = realNumber < number;

        return (
            <span
                className={classNames(
                    styles.detailsText,
                    {
                        [styles.positive]: differenceIsPositive,
                        [styles.negative]: !differenceIsPositive,
                    },
                )}
            >
                {realNumber} {postfix}
            </span>
        );
    }

    function renderChangeDetail() {
        if (!showWithDetails) {
            return null;
        }

        const iconType      = connectionDetailType === ConnectionDetailType.changeTime
            ? IconType.clock
            : IconType.passengerVolume;
        const detailPostfix = connectionDetailType === ConnectionDetailType.changeTime
            ? I18n.t('minutesShort')
            : null;

        return (
            <div
                className={styles.changeDetails}
            >
                <Icon
                    iconType={iconType}
                    iconColor={IconColor.gray}
                />
                <span className={styles.detailsText}>
                    {changeDetailNumber} {detailPostfix}
                </span>
                {renderRouteRealNumber(changeDetailNumber, changeDetailRealNumber, detailPostfix)}
            </div>
        );
    }

    function renderTextWrapper() {
        const textWrapper = (
            <span ref={textWrapperRef}>
                {changeStopName}
            </span>
        );

        if (shouldTooltipBeShown) {
            return (
                <Tippy content={changeStopName}>
                    {textWrapper}
                </Tippy>
            );
        }

        return textWrapper;
    }

    if (isLastRoute) {
        return null;
    }

    return (
        <div
            className={styles.routeChangeIcon}
            style={changeIconStyle}
        >
            {renderChangeDetail(changeDetailNumber, changeDetailRealNumber)}
            <Icon
                iconType={IconType.change}
                iconColor={IconColor.gray}
            />
            <div
                className={styles.changeStopName}
                style={changeStopNameStyle}
            >
                {renderTextWrapper()}
            </div>
        </div>
    );
};

SidebarConnectionChange.propTypes = propTypes;

export default SidebarConnectionChange;
