//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useEffect } from 'react';

import * as Leaflet       from 'leaflet';
import _                  from 'lodash';
import { renderToString } from 'react-dom/server';
import { useMap }         from 'react-leaflet';

import PropTypes from '@components/PropTypes';

// eslint-disable-next-line rulesdir/format-import-linter
import 'leaflet.markercluster';

const propTypes = {
    markers:          PropTypes.arrayOf(PropTypes.shape({
        position:  PropTypes.object,
        component: PropTypes.element,
    })),
    renderClusterPin: PropTypes.func,
};

const MarkerCluster = ({
    markers          = [],
    renderClusterPin = null,
}) => {
    const map = useMap();

    useEffect(
        () => {
            const options = _.stubObject();

            if (renderClusterPin) {
                options.iconCreateFunction = renderClusterPin;
            }

            const markerClusterGroup = Leaflet.markerClusterGroup(options);

            markers.forEach(({ position, component, onClick }) => {
                const markerComponentHtml = renderToString(component);
                const markerCustomIcon    = Leaflet.divIcon({
                    html: `<div>${markerComponentHtml}</div>`,
                });

                Leaflet.marker(new Leaflet.LatLng(position.latitude, position.longitude), {
                    icon: markerCustomIcon,
                })
                    .on('click', onClick)
                    .addTo(markerClusterGroup);
            });

            map.addLayer(markerClusterGroup);

            return () => {
                map.removeLayer(markerClusterGroup);
            };
        },
        [markers, map],
    );

    return null;
};

MarkerCluster.propTypes = propTypes;

export default MarkerCluster;
