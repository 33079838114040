//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import CacheClearButton from '@connected/CacheClearButton';
import HistoryAccessor  from '@connected/HistoryAccessor';
import UserDropDown     from '@connected/UserDropDown';
import Header           from '@stateless/composed/Header';

const propTypes = {};

const HomeHeader = () => {
    function renderRightContent() {
        return (
            <>
                <CacheClearButton />
                <UserDropDown />
            </>
        );
    }

    function renderCenterContent() {
        return (
            <HistoryAccessor />
        );
    }

    return (
        <Header
            rightContent={renderRightContent()}
            centerContent={renderCenterContent()}
        />
    );
};

HomeHeader.propTypes = propTypes;

export default HomeHeader;
