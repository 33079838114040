//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useRef }    from 'react';
import { useMemo }   from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import classNames         from 'classnames';
import _                  from 'lodash';
import { useTranslation } from 'react-i18next';

import PropTypes               from '@components/PropTypes';
import ConnectionDetailType    from '@constants/ConnectionDetailType';
import ShortNameLabel          from '@stateless/atomic/ShortNameLabel';
import SidebarConnectionChange from '@stateless/atomic/SidebarConnectionChange';

import styles from './styles.module.scss';

const CHANGE_ICON_WIDTH = 20;

const propTypes = {
    connectionDetailType: PropTypes.oneOfObjectValues(ConnectionDetailType),
    fromStopName:         PropTypes.string,
    onItemClicked:        PropTypes.func,
    percentageDelay:      PropTypes.number,
    routes:               PropTypes.array,
    showPercentageDelay:  PropTypes.bool,
    toStopName:           PropTypes.string,
};

const SidebarConnectionItem = ({
    fromStopName         = '',
    routes               = [],
    toStopName           = '',
    onItemClicked        = _.noop,
    connectionDetailType = null,
    percentageDelay      = 0,
    showPercentageDelay  = true,
}) => {
    const routesWrapperRef                = useRef(null);
    const { t }                           = useTranslation(null, {
        keyPrefix: 'components.search',
    });
    const showWithDetails                 = useMemo(() => {
        return connectionDetailType !== null;
    }, [connectionDetailType]);
    const [wrapperWidth, setWrapperWidth] = useState(0);

    useEffect(() => {
        if (routesWrapperRef.current) {
            setWrapperWidth(routesWrapperRef.current.clientWidth);
        }
    }, []);

    const renderRoutesLabels = () => {
        return routes.map((route, index) => {
            if (!wrapperWidth) {
                return null;
            }

            const { percentageWidth, name, color, onRouteClicked, disabled } = route;
            // @formatter:off because it's a calculation
            const fixedWidth                                                 = ((wrapperWidth - (CHANGE_ICON_WIDTH * (routes.length - 1))) / 100) * percentageWidth;
            // @formatter:on
            const isLastRoute                                                = index === routes.length - 1;
            const labelKey                                                   = `${name}-${index}`;

            return (
                <React.Fragment
                    key={labelKey}
                >
                    <ShortNameLabel
                        fixedWidth={fixedWidth}
                        text={name}
                        color={color}
                        onLabelClicked={onRouteClicked}
                        disabled={disabled}
                    />
                    <SidebarConnectionChange
                        fixedWidth={fixedWidth}
                        isLastRoute={isLastRoute}
                        route={route}
                        connectionDetailType={connectionDetailType}
                        showWithDetails={showWithDetails}
                        changeIconWidth={CHANGE_ICON_WIDTH}
                    />
                </React.Fragment>
            );
        });
    };

    function renderPunctualityLabel() {
        if (
            !showWithDetails ||
            _.isNaN(percentageDelay) ||
            !showPercentageDelay
        ) {
            return null;
        }

        let text = t('punctualityDelay', {
            percentage: percentageDelay,
        });

        if (percentageDelay >= 100) {
            text = t('punctualityNotReachable');
        } else if (percentageDelay <= 0) {
            text = t('punctualityOnTime');
        }

        return (
            <div className={styles.punctualityLabel}>
                {text}
            </div>
        );
    }

    return (
        <div
            className={classNames(
                styles.sidebarConnectionItem,
                {
                    [styles.withDetails]: showWithDetails,
                },
            )}
            onClick={onItemClicked}
        >
            <div
                className={styles.routeDetailsRow}
            >
                <div className={styles.routeInformation}>
                    <div
                        ref={routesWrapperRef}
                        className={styles.routesWrapper}
                    >
                        {renderPunctualityLabel()}
                        {renderRoutesLabels()}
                    </div>
                    <div className={styles.fromToLabel}>
                        <span>
                            {fromStopName}
                        </span>
                        <span>
                            {toStopName}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

SidebarConnectionItem.propTypes = propTypes;

export default SidebarConnectionItem;
