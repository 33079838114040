//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                from 'lodash';
import { useMapEvents } from 'react-leaflet';

import useMapAccessor from '@hooks/useMapAccessor';

const MAP_DEBOUNCE_IN_MILLISECONDS = 500;

const MapEvents = () => {
    const { updateCoordinates, updateZoom } = useMapAccessor();
    const map                               = useMapEvents({
        moveend: () => {
            _.debounce(updateCoordinates, MAP_DEBOUNCE_IN_MILLISECONDS)(map);
        },
        zoomend: () => {
            _.debounce(updateZoom, MAP_DEBOUNCE_IN_MILLISECONDS)(map);
        },
    });

    return null;
};

export default MapEvents;
