//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

class ActionHelper {
    static getApiActionsToWhitelist(api) {
        const actionsToWhitelist = [];
        const internalActions    = api.internalActions;

        _.each(Object.keys(internalActions), (key) => {
            actionsToWhitelist.push(internalActions[key].type);
        });

        return actionsToWhitelist;
    }

    static getMultipleApiActionsToWhitelist(apis) {
        const actionsToWhitelist = [];

        _.each(apis, (api) => {
            actionsToWhitelist.push(...ActionHelper.getApiActionsToWhitelist(api));
        });

        return actionsToWhitelist;
    }
}

export default ActionHelper;
