//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import IconArrowRight      from '@assets/icons/arrow-right.svg';
import IconAverage         from '@assets/icons/average.svg';
import IconBurger          from '@assets/icons/burger.svg';
import IconBus             from '@assets/icons/bus.svg';
import IconCalendar        from '@assets/icons/calendar.svg';
import IconChange          from '@assets/icons/change.svg';
import IconChevronDown     from '@assets/icons/chevron-down.svg';
import IconChevronLeft     from '@assets/icons/chevron-left.svg';
import IconChevronRight    from '@assets/icons/chevron-right.svg';
import IconChevronUp       from '@assets/icons/chevron-up.svg';
import IconClock           from '@assets/icons/clock.svg';
import IconCloud           from '@assets/icons/cloud.svg';
import IconConnections     from '@assets/icons/connections.svg';
import IconConstructions   from '@assets/icons/construction.svg';
import IconCross2          from '@assets/icons/cross.svg';
import IconDashboard       from '@assets/icons/dashboard.svg';
import IconDocument        from '@assets/icons/document.svg';
import IconFerry           from '@assets/icons/ferry.svg';
import IconFullscreen      from '@assets/icons/fullscreen.svg';
import IconLine            from '@assets/icons/line.svg';
import IconLock            from '@assets/icons/lock.svg';
import IconLogo            from '@assets/icons/logo.svg';
import IconLogout          from '@assets/icons/logout.svg';
import IconMail            from '@assets/icons/mail.svg';
import IconMinus           from '@assets/icons/minus.svg';
import IconPassengerVolume from '@assets/icons/passenger-volume.svg';
import IconPlus            from '@assets/icons/plus.svg';
import IconProfile         from '@assets/icons/profile.svg';
import IconPunctuality     from '@assets/icons/punctuality.svg';
import IconSearch          from '@assets/icons/search.svg';
import IconSort            from '@assets/icons/sort.svg';
import IconStandOut        from '@assets/icons/stand-out.svg';
import IconStar            from '@assets/icons/star.svg';
import IconTrain           from '@assets/icons/train.svg';
import IconTrash           from '@assets/icons/trash.svg';
import IconUser            from '@assets/icons/user.svg';
import IconWarning         from '@assets/icons/warning.svg';
import PropTypes           from '@components/PropTypes';

import IconColor from './IconColor';
import IconType  from './IconType';
import styles    from './styles.module.scss';

const propTypes = {
    iconColor: PropTypes.iconColor,
    iconType:  PropTypes.iconType,
};

const Icon = ({
    iconType  = null,
    iconColor = IconColor.black,
}) => {
    function renderSvg() {
        const iconMapping = {
            [IconType.chevronRight]:    <IconChevronRight />,
            [IconType.cross]:           <IconCross2 />,
            [IconType.logo]:            <IconLogo />,
            [IconType.star]:            <IconStar />,
            [IconType.burger]:          <IconBurger />,
            [IconType.bus]:             <IconBus />,
            [IconType.ferry]:           <IconFerry />,
            [IconType.cloud]:           <IconCloud />,
            [IconType.connections]:     <IconConnections />,
            [IconType.construction]:    <IconConstructions />,
            [IconType.passengerVolume]: <IconPassengerVolume />,
            [IconType.punctuality]:     <IconPunctuality />,
            [IconType.standOut]:        <IconStandOut />,
            [IconType.train]:           <IconTrain />,
            [IconType.warning]:         <IconWarning />,
            [IconType.change]:          <IconChange />,
            [IconType.line]:            <IconLine />,
            [IconType.calendar]:        <IconCalendar />,
            [IconType.chevronUp]:       <IconChevronUp />,
            [IconType.arrowRight]:      <IconArrowRight />,
            [IconType.sort]:            <IconSort />,
            [IconType.chevronDown]:     <IconChevronDown />,
            [IconType.dashboard]:       <IconDashboard />,
            [IconType.fullscreen]:      <IconFullscreen />,
            [IconType.minus]:           <IconMinus />,
            [IconType.plus]:            <IconPlus />,
            [IconType.trash]:           <IconTrash />,
            [IconType.user]:            <IconUser />,
            [IconType.average]:         <IconAverage />,
            [IconType.clock]:           <IconClock />,
            [IconType.lock]:            <IconLock />,
            [IconType.search]:          <IconSearch />,
            [IconType.mail]:            <IconMail />,
            [IconType.profile]:         <IconProfile />,
            [IconType.document]:        <IconDocument />,
            [IconType.logout]:          <IconLogout />,
            [IconType.chevronRight]:    <IconChevronRight />,
            [IconType.chevronLeft]:     <IconChevronLeft />,
        };

        return iconMapping[iconType];
    }

    return (
        <span
            className={classNames(styles.icon, {
                [styles.yellow]:    iconColor === IconColor.yellow,
                [styles.black]:     iconColor === IconColor.black,
                [styles.darkAbyss]: iconColor === IconColor.darkAbyss,
                [styles.white]:     iconColor === IconColor.white,
                [styles.red]:       iconColor === IconColor.red,
                [styles.gray]:      iconColor === IconColor.gray,
            })}
        >
            {renderSvg()}
        </span>
    );
};

Icon.propTypes = propTypes;

export default Icon;
