//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector }    from 'react-redux';
import { useDispatch }    from 'react-redux';

import QuickViewContent        from '@connected/QuickViewContent';
import SelectedDateRangeLabel  from '@connected/SelectedDateRangeLabel';
import QuickViewType           from '@constants/QuickViewType';
import SearchMode              from '@constants/SearchMode';
import withSidebarListPadding  from '@hoc/SidebarListPadding';
import { useQuickViewActions } from '@slices/quickView';
import IconType                from '@stateless/atomic/Icon/IconType';
import LabeledSwitch           from '@stateless/atomic/LabeledSwitch';
import UppercaseLabel          from '@stateless/atomic/UppercaseLabel';
import SidebarSeparator        from '@stateless/composed/SidebarSeparator';

const propTypes = {};

const QuickViewSidebarDetails = () => {
    const { t }                  = useTranslation(null, {
        keyPrefix: 'components.action',
    });
    const activeQuickActionType  = useSelector((state) => state.quickView.activeQuickViewType);
    const searchMode             = useSelector((state) => state.quickView.searchMode);
    const dispatch               = useDispatch();
    const QuickViewActions       = useQuickViewActions(dispatch);
    const onOptionClicked        = (option) => {
        QuickViewActions.setSearchMode({
            searchMode: option,
        });
    };
    const renderLabeledSwitch    = () => {
        if (
            activeQuickActionType !== QuickViewType.mostTransits &&
            activeQuickActionType !== QuickViewType.leastTransits
        ) {
            return null;
        }

        return withSidebarListPadding(<LabeledSwitch
            leftOption={SearchMode.stops}
            rightOption={SearchMode.routes}
            selectedOption={searchMode}
            onOptionClicked={onOptionClicked}
            width={240}
        />);
    };
    const renderInformationLabel = () => {
        if (
            activeQuickActionType !== QuickViewType.reliableTransits &&
            activeQuickActionType !== QuickViewType.unreliableTransits
        ) {
            return null;
        }

        return withSidebarListPadding(<UppercaseLabel
            text={t('connectionInfo')}
            iconType={IconType.average}
        />);
    };
    const renderControlRow       = () => {
        return (
            <>
                {renderLabeledSwitch()}
                {renderInformationLabel()}
            </>
        );
    };

    useEffect(() => {
        if (
            activeQuickActionType === QuickViewType.reliableTransits ||
            activeQuickActionType === QuickViewType.unreliableTransits
        ) {
            QuickViewActions.setSearchMode({
                searchMode: SearchMode.routes,
            });
        }
    }, [activeQuickActionType]);

    return (
        <div>
            <SidebarSeparator
                text={t(activeQuickActionType)}
                rightContent={<SelectedDateRangeLabel />}
            />
            {renderControlRow()}
            <QuickViewContent />
        </div>
    );
};

QuickViewSidebarDetails.propTypes = propTypes;

export default QuickViewSidebarDetails;
