//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createApi }      from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import ApiUrls from '@constants/ApiUrls';
import Url     from '@helper/Url';

export const regionApi = createApi({
    reducerPath:       'regionApi',
    baseQuery:         fetchBaseQuery({
        baseUrl: Url.createEndpoint(ApiUrls.API_REGION_URL),
    }),
    keepUnusedDataFor: 0,
    endpoints:         (builder) => (
        {
            fetchRegion: builder.query({
                query: ({ bounds, dateRange, filter }) => (
                    {
                        url:    '',
                        params: {
                            bounds,
                            dateRange,
                            filter,
                        },
                    }
                ),
            }),
        }
    ),
});

export const { useFetchRegionQuery } = regionApi;

export const RegionApiReducer = regionApi.reducer;

export const RegionApiReducerPath = regionApi.reducerPath;

export const RegionApiMiddleware = regionApi.middleware;

export default regionApi;
