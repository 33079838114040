//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import SearchMode                       from '@constants/SearchMode';
import DateRangeHelper                  from '@helper/DateRange';
import { useFetchConnectionsViewQuery } from '@store/api/connectionsView';

const useConnectionsViewApi = () => {
    const activeQuickViewType                    = useSelector((state) => state.quickView.activeQuickViewType);
    const showQuickViewSidebarDetails            = useSelector((state) => state.quickView.showQuickViewSidebarDetails);
    const activeSearchMode                       = useSelector((state) => state.quickView.searchMode);
    const selectedDateRange                      = useSelector((state) => state.map.selectedDateRange);
    const dateRangeString                        = DateRangeHelper.getRangeString(selectedDateRange);
    const target                                 = useMemo(() => {
        if (
            activeSearchMode === SearchMode.routes
        ) {
            return 'transits';
        }

        return 'stops';
    }, [activeSearchMode]);
    const options                                = {
        view:      activeQuickViewType,
        dateRange: dateRangeString,
        target,
    };
    const shouldSkip                             = (
        !showQuickViewSidebarDetails ||
        !activeQuickViewType
    );
    const { data, error, isLoading, isFetching } = useFetchConnectionsViewQuery(options, {
        skip: shouldSkip,
    });

    return {
        data,
        error,
        isLoading,
        isFetching,
    };
};

export default useConnectionsViewApi;
