//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes    from '@components/PropTypes';
import HeadlineType from '@stateless/atomic/Headline/HeadlineType';

import styles from './styles.module.scss';

const propTypes = {
    title:        PropTypes.string.isRequired,
    titlePostfix: PropTypes.string,
    titlePrefix:  PropTypes.string,
    type:         PropTypes.headlineType,
};

const Headline = ({
    title        = null,
    titlePostfix = null,
    titlePrefix  = null,
    type         = HeadlineType.headline2,
}) => {
    let prefix  = null;
    let postfix = null;

    if (titlePrefix) {
        prefix = React.createElement(
            'span',
            {},
            `${titlePrefix} `,
        );
    }

    if (titlePostfix) {
        postfix = React.createElement(
            'span',
            {},
            ` ${titlePostfix}`,
        );
    }

    return React.createElement(
        type,
        {
            className: styles.headline,
        },
        prefix,
        title,
        postfix,
    );
};

Headline.propTypes = propTypes;

export default Headline;
