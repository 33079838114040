//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import classNames from 'classnames';

import PropTypes       from '@components/PropTypes';
import CircleIconSize  from '@stateless/atomic/CircleIcon/CircleIconSize';
import CircleIconTheme from '@stateless/atomic/CircleIcon/CircleIconTheme';
import Icon            from '@stateless/atomic/Icon';

import styles from './styles.module.scss';

const propTypes = {
    circleIconTheme: PropTypes.oneOfObjectKeys(CircleIconTheme),
    iconType:        PropTypes.iconType,
    size:            PropTypes.oneOfObjectKeys(CircleIconSize),
    text:            PropTypes.any,
};

const CircleIcon = ({
    iconType        = null,
    circleIconTheme = CircleIconTheme.transparent,
    size            = CircleIconSize.default,
    text            = '',
}) => {
    function renderContent() {
        if (iconType) {
            return (
                <Icon
                    iconType={iconType}
                />
            );
        }

        return (
            <span className={styles.circleText}>
                {text}
            </span>
        );
    }

    return (
        <div
            className={classNames(
                styles.circleIcon,
                {
                    [styles.circleIconYellow]:      circleIconTheme === CircleIconTheme.yellow,
                    [styles.circleIconRed]:         circleIconTheme === CircleIconTheme.red,
                    [styles.circleIconDarkAbyss]:   circleIconTheme === CircleIconTheme.darkAbyss,
                    [styles.circleIconGray]:        circleIconTheme === CircleIconTheme.gray,
                    [styles.circleIconBlue]:        circleIconTheme === CircleIconTheme.blue,
                    [styles.circleIconTransparent]: circleIconTheme === CircleIconTheme.transparent,
                    [styles.circleIconBig]:         size === CircleIconSize.big,
                },
            )}
        >
            {renderContent()}
        </div>
    );
};

CircleIcon.propTypes = propTypes;

export default CircleIcon;
