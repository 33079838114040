//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import { useCallback } from 'react';
import { useMemo }     from 'react';

import classNames      from 'classnames';
import I18n            from 'i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import MapProvider            from '@components/MapProvider';
import ControlLayer           from '@connected/ControlLayer';
import HomeHeader             from '@connected/HomeHeader';
import MapActionsSidebar      from '@connected/MapActionsSidebar';
import MapLayer               from '@connected/MapLayer';
import MapSearchSidebar       from '@connected/MapSearchSidebar';
import SidebarTheme           from '@constants/SidebarTheme';
import useStateQueryParameter from '@hooks/useStateParameter';
import { useMapActions }      from '@slices/map';
import PageTitle              from '@stateless/composed/PageTitle';

import styles from './styles.module.scss';

const Home = () => {
    useStateQueryParameter();

    const dispatch                         = useDispatch();
    const mapActions                       = useMapActions(dispatch);
    const showMapSearchSidebar             = useSelector((state) => state.map.showMapSearchSidebar);
    const showMapActionsSidebar            = useSelector((state) => state.map.showMapActionsSidebar);
    const onMapActionsSidebarToggleClicked = useCallback(
        () => {
            mapActions.setShowMapActionsSidebar({
                show: !showMapActionsSidebar,
            });
        },
        [showMapActionsSidebar],
    );
    const onMapSearchSidebarToggleClicked  = useCallback(
        () => {
            mapActions.setShowMapSearchSidebar({
                show: !showMapSearchSidebar,
            });
        },
        [showMapSearchSidebar],
    );
    const isDateRangePickerVisible         = useSelector((state) => state.map.showDateRangePicker);
    const mapActionSidebarTheme            = useMemo(() => {
        if (
            showMapSearchSidebar ||
            !isDateRangePickerVisible
        ) {
            return SidebarTheme.default;
        }

        return SidebarTheme.lightGray;
    }, [isDateRangePickerVisible, showMapSearchSidebar]);

    return (
        <>
            <PageTitle title={I18n.t('pageHome')} />
            <MapProvider>
                <HomeHeader />
                <MapActionsSidebar
                    onSidebarToggleClicked={onMapActionsSidebarToggleClicked}
                    expanded={showMapActionsSidebar}
                    theme={mapActionSidebarTheme}
                />
                <MapSearchSidebar
                    onSidebarToggleClicked={onMapSearchSidebarToggleClicked}
                    expanded={showMapSearchSidebar}
                />
                <div
                    className={classNames(
                        styles.homeScreen,
                        {
                            [styles.mapActionsSidebarExpanded]: showMapActionsSidebar,
                            [styles.mapSearchSidebarExpanded]:  showMapSearchSidebar,
                        },
                    )}
                >
                    <div className={styles.homeScreenContent}>
                        <ControlLayer />
                        <MapLayer />
                    </div>
                </div>
            </MapProvider>
        </>
    );
};
export default Home;
