//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

import classNames from 'classnames';

import PropTypes   from '@components/PropTypes';
import Icon        from '@stateless/atomic/Icon';
import IconColor   from '@stateless/atomic/Icon/IconColor';
import IconType    from '@stateless/atomic/Icon/IconType';
import OverlayMenu from '@stateless/atomic/OverlayMenu';

import styles from './styles.module.scss';

const propTypes = {
    iconType:  PropTypes.string,
    menuItems: PropTypes.array,
    text:      PropTypes.string,
};

const DropDownMenu = ({
    iconType  = null,
    text      = '',
    menuItems = [],
}) => {
    const [menuOpen, setMenuOpen] = useState(false);

    function onProfileClicked() {
        setMenuOpen((current) => !current);
    }

    return (
        <div className={styles.dropDownMenuComponent}>
            <button
                className={styles.profileWrapper}
                onClick={onProfileClicked}
            >
                <Icon
                    iconType={iconType}
                    iconColor={IconColor.darkAbyss}
                />
                <div className={styles.userName}>
                    {text}
                </div>
                <div
                    className={classNames(
                        styles.dropDownIcon,
                        {
                            [styles.rotated]: menuOpen,
                        },
                    )}
                >
                    <Icon
                        iconType={IconType.chevronDown}
                        iconColor={IconColor.darkAbyss}
                    />
                </div>
            </button>
            <OverlayMenu
                open={menuOpen}
                menuItems={menuItems}
            />
        </div>
    );
};

DropDownMenu.propTypes = propTypes;

export default DropDownMenu;
