//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createApi }      from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import ApiUrls        from '@constants/ApiUrls';
import DurationHelper from '@helper/DurationHelper';
import Url            from '@helper/Url';

export const calendarApi = createApi({
    reducerPath:       'calendarApi',
    baseQuery:         fetchBaseQuery({
        baseUrl: Url.createEndpoint(ApiUrls.API_CALENDAR_URL),
    }),
    keepUnusedDataFor: DurationHelper.daysToSeconds(1),
    endpoints:         (builder) => (
        {
            fetchCalendar: builder.query({
                query: ({ dateRange }) => (
                    {
                        url:    '',
                        params: {
                            dateRange,
                        },
                    }
                ),
            }),
        }
    ),
});

export const { useFetchCalendarQuery } = calendarApi;

export const CalendarApiReducer = calendarApi.reducer;

export const CalendarApiReducerPath = calendarApi.reducerPath;

export const CalendarApiMiddleware = calendarApi.middleware;

export default calendarApi;
