//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import PropTypes  from '@components/PropTypes';
import IconColor  from '@stateless/atomic/Icon/IconColor';
import IconButton from '@stateless/atomic/IconButton';

import styles from './styles.module.scss';

const propTypes = {
    headline:      PropTypes.string,
    iconType:      PropTypes.iconType,
    onIconClicked: PropTypes.func,
};

const SidebarHeader = ({
    iconType      = null,
    onIconClicked = _.noop,
    headline      = '',
}) => {
    return (
        <div className={styles.sidebarHeaderComponent}>
            <IconButton
                iconColor={IconColor.darkAbyss}
                className={styles.sidebarButton}
                iconType={iconType}
                onIconButtonClicked={onIconClicked}
            />
            <span className={styles.sidebarTitle}>
                {headline}
            </span>
        </div>
    );
};

SidebarHeader.propTypes = propTypes;

export default SidebarHeader;
