//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import { useMemo } from 'react';

import classNames from 'classnames';

import PropTypes           from '@components/PropTypes';
import NavigationDirection from '@stateless/atomic/BarGraphSwiperNavigationButton/NavigationDirection';
import IconType            from '@stateless/atomic/Icon/IconType';
import IconButton          from '@stateless/atomic/IconButton';

import styles from './styles.module.scss';

const propTypes = {
    direction: PropTypes.oneOfObjectValues(NavigationDirection),
    disabled:  PropTypes.bool,
    swiperRef: PropTypes.object,
};

const BarGraphSwiperNavigationButton = ({
    direction = NavigationDirection.next,
    disabled  = false,
    swiperRef = null,
}) => {
    const iconType                  = useMemo(() => {
        if (direction === NavigationDirection.previous) {
            return IconType.chevronLeft;
        }

        return IconType.chevronRight;
    }, [direction]);
    const onNavigationButtonClicked = () => {
        if (direction === NavigationDirection.previous) {
            swiperRef.current.slidePrev();
        } else {
            swiperRef.current.slideNext();
        }
    };

    return (
        <IconButton
            className={classNames(
                styles.barGraphSwiperNavigationButton,
                {
                    [styles.navigationPrevious]: direction === NavigationDirection.previous,
                    [styles.navigationNext]:     direction === NavigationDirection.next,
                },
            )}
            iconType={iconType}
            onIconButtonClicked={onNavigationButtonClicked}
            disabled={disabled}
        />
    );
};

BarGraphSwiperNavigationButton.propTypes = propTypes;

export default BarGraphSwiperNavigationButton;
