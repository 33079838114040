//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    API_TOKENS:            'api/tokens',              // TODO-Skeleton
    API_REGION_URL:        'api/query/region',
    API_POLYLINE_URL:      'api/query/polyline',
    API_POLYLINES_URL:     'api/query/polylines',
    API_CONTEXT_URL:       'api/query/context',
    API_SEARCH_STOPS_URL:  'api/query/stops',
    API_SEARCH_TRIPS_URL:  'api/query/trips',
    API_CALENDAR_URL:      'api/query/calendar',
    API_SEARCH_ROUTES_URL: 'api/query/routes',
    API_CONNECTIONS_VIEW:  'api/query/connections-view',
});
