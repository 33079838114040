//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';

import Coordinates          from '@constants/Coordinates';
import MapFilter            from '@constants/MapFilter';
import Zoom                 from '@constants/Zoom';
import { DateRangeDefault } from '@helper/DateRange';

const initialSelectedContext = {
    id:         null,
    type:       null,
    dateRange:  null,
    activeTab:  null,
    tabOptions: [],
};

const initialState = Object.freeze({
    coordinates:           Coordinates.Ravensburg,
    filter:                [
        MapFilter.lines,
        MapFilter.busStops,
        MapFilter.trainStations,
        MapFilter.ferryStations,
    ],
    zoom:                  Zoom.InitialZoom,
    selectedContext:       initialSelectedContext,
    showDateRangePicker:   false,
    selectedDateRange:     DateRangeDefault,
    showMapSearchSidebar:  false,
    showMapActionsSidebar: false,
});

const mapSlice = createSlice({
    name:     'map',
    initialState,
    reducers: {
        setFilter:                (state, action) => {
            const filter = action.payload;

            return update(state, {
                filter: {
                    $set: _.uniq(filter),
                },
            });
        },
        setCoordinates:           (state, action) => {
            const { latitude, longitude, bounds } = action.payload;

            return update(state, {
                coordinates: {
                    $set: {
                        latitude,
                        longitude,
                    },
                },
                bounds:      {
                    $set: bounds,
                },
            });
        },
        setZoom:                  (state, action) => {
            const { zoom, bounds } = action.payload;

            return update(state, {
                zoom:   {
                    $set: zoom,
                },
                bounds: {
                    $set: bounds,
                },
            });
        },
        setSelectedContext:       (state, action) => {
            const { id, type, dateRange, activeTab, tabOptions } = action.payload;

            return update(state, {
                selectedContext: {
                    $set: {
                        ...initialSelectedContext,
                        id,
                        type,
                        dateRange,
                        activeTab,
                        tabOptions,
                    },
                },
            });
        },
        setShowDateRangePicker:   (state, action) => {
            const { show } = action.payload;

            return update(state, {
                showDateRangePicker: {
                    $set: show,
                },
            });
        },
        setSelectedDateRange:     (state, action) => {
            const { from, to } = action.payload;

            return update(state, {
                selectedDateRange: {
                    $set: {
                        from,
                        to,
                    },
                },
                selectedContext:   {
                    dateRange: {
                        $set: {
                            from,
                            to,
                        },
                    },
                },
            });
        },
        setShowMapSearchSidebar:  (state, action) => {
            const { show } = action.payload;

            return update(state, {
                showMapSearchSidebar: {
                    $set: show,
                },
            });
        },
        setShowMapActionsSidebar: (state, action) => {
            const { show } = action.payload;

            return update(state, {
                showMapActionsSidebar: {
                    $set: show,
                },
            });
        },
    },
});

export const MapActions = mapSlice.actions;

export const MapReducer = mapSlice.reducer;

export const useMapActions = (dispatch) => bindActionCreators(MapActions, dispatch);

export default mapSlice;
