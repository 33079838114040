//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createApi }      from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import ApiUrls        from '@constants/ApiUrls';
import DurationHelper from '@helper/DurationHelper';
import Url            from '@helper/Url';

export const contextApi = createApi({
    reducerPath:       'contextApi',
    baseQuery:         fetchBaseQuery({
        baseUrl: Url.createEndpoint(ApiUrls.API_CONTEXT_URL),
    }),
    keepUnusedDataFor: DurationHelper.daysToSeconds(1),
    endpoints:         (builder) => (
        {
            fetchContext: builder.query({
                query: ({ id, dateRange, type }) => (
                    {
                        url:    '',
                        method: 'POST',
                        body:   {
                            id,
                            dateRange,
                            type,
                        },
                    }
                ),
            }),
        }
    ),
});

export const { useFetchContextQuery } = contextApi;

export const ContextApiReducer = contextApi.reducer;

export const ContextApiReducerPath = contextApi.reducerPath;

export const ContextApiMiddleware = contextApi.middleware;

export default contextApi;
