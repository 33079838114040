//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { CalendarApiMiddleware }        from '@store/api/calendar';
import { ConnectionsViewApiMiddleware } from '@store/api/connectionsView';
import { ContextApiMiddleware }         from '@store/api/context';
import { PolylineApiMiddleware }        from '@store/api/polyline';
import { PolylinesApiMiddleware }       from '@store/api/polylines';
import { RegionApiMiddleware }          from '@store/api/region';
import { RoutesApiMiddleware }          from '@store/api/routes';
import { StopsApiMiddleware }           from '@store/api/stops';
import { TripsApiMiddleware }           from '@store/api/trips';

export default [
    RegionApiMiddleware,
    PolylineApiMiddleware,
    PolylinesApiMiddleware,
    ContextApiMiddleware,
    StopsApiMiddleware,
    TripsApiMiddleware,
    RoutesApiMiddleware,
    ConnectionsViewApiMiddleware,
    CalendarApiMiddleware,
];
