//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import DateRangeHelper       from '@helper/DateRange';
import useContextHistory     from '@hooks/useContextHistory';
import { useMapActions }     from '@slices/map';
import selectSelectedContext from '@store/selectors/map';

const useContextLoader = () => {
    const dispatch                               = useDispatch();
    const MapActions                             = useMapActions(dispatch);
    const selectedDateRange                      = useSelector((state) => state.map.selectedDateRange);
    const selectedContext                        = useSelector(selectSelectedContext);
    const { updateHistoryItem, unselectHistory } = useContextHistory();

    function clearContext() {
        MapActions.setSelectedContext({
            id:         null,
            type:       null,
            dateRange:  null,
            activeTab:  null,
            tabOptions: {},
        });
        unselectHistory();
    }

    function loadInContext({ id, type, dateRange, activeTab, tabOptions }) {
        const contextItem = {
            id,
            type,
            dateRange: dateRange || selectedDateRange,
            activeTab,
            tabOptions,
        };

        MapActions.setSelectedContext(contextItem);

        if (!DateRangeHelper.areDateRangesEqual(contextItem.dateRange, selectedDateRange)) {
            MapActions.setSelectedDateRange(dateRange);
        }

        updateHistoryItem(contextItem);
    }

    function setActiveTab(activeTab) {
        loadInContext({
            ...selectedContext,
            activeTab,
        });
    }

    function setTabOption(tab, option) {
        const tabOptions    = selectedContext.tabOptions || {};
        const newTabOptions = {
            ...tabOptions,
            [tab]: option,
        };

        loadInContext({
            ...selectedContext,
            tabOptions: newTabOptions,
        });
    }

    function setSelectedBarIndex(tab, index) {
        const tabOptions = selectedContext?.tabOptions;
        let newTabOption = {};

        if (
            !tabOptions ||
            !tabOptions[tab]
        ) {
            newTabOption = {
                selectedBarIndex: index,
            };
        } else {
            newTabOption = {
                ...tabOptions[tab],
                selectedBarIndex: index,
            };
        }

        setTabOption(tab, newTabOption);
    }

    function getSelectedBarIndex(tab) {
        const tabOptions = selectedContext?.tabOptions;

        if (
            !tabOptions ||
            !tabOptions[tab]
        ) {
            return 0;
        }

        return tabOptions[tab]?.selectedBarIndex;
    }

    return {
        selectedContext,
        clearContext,
        loadInContext,
        setActiveTab,
        setSelectedBarIndex,
        getSelectedBarIndex,
    };
};

export default useContextLoader;
