//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import update                 from 'immutability-helper';
import { bindActionCreators } from 'redux';

const initialState = Object.freeze({
    routeFromStop:          {
        id:   '',
        name: '',
    },
    routeToStop:            {
        id:   '',
        name: '',
    },
    showRouteSearchResults: false,
});

const searchSlice = createSlice({
    name:     'search',
    initialState,
    reducers: {
        setRouteFromStop:          (state, action) => {
            const { id, name } = action.payload;

            return update(state, {
                routeFromStop: {
                    $set: {
                        id,
                        name,
                    },
                },
            });
        },
        setRouteToStop:            (state, action) => {
            const { id, name } = action.payload;

            return update(state, {
                routeToStop: {
                    $set: {
                        id,
                        name,
                    },
                },
            });
        },
        setShowRouteSearchResults: (state, action) => {
            const { showRouteSearchResults } = action.payload;

            return update(state, {
                showRouteSearchResults: {
                    $set: showRouteSearchResults,
                },
            });
        },
    },
});

export const SearchActions = searchSlice.actions;

export const SearchReducer = searchSlice.reducer;

export const useSearchActions = (dispatch) => bindActionCreators(SearchActions, dispatch);

export default searchSlice;
