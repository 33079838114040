//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import Api from '@constants/Api';

class Url {
    static openInNewTab(url) {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    static createEndpoint(path) {
        return new URL(path, Api.getBackendUrl()).toString();
    }

    static resizedImage(path, width = null, height = null, minWidth = null, minHeight = null, extension = null) {
        const completeFilePath = Api.getBackendUrl() + path;
        const additionalParams = {
            width,
            height,
            minWidth,
            minHeight,
            extension,
            origin: completeFilePath,
            seed:   path,
        };
        const url              = new URL(`${Api.getBackendUrl()}/minify-image`);
        const params           = new URLSearchParams();

        Object.keys(additionalParams).forEach((key) => {
            if (additionalParams[key]) {
                params.append(key, additionalParams[key]);
            }
        });

        return `${url}?${params.toString()}`;
    }
}

export default Url;
