//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import PropTypes       from '@components/PropTypes';
import Icon            from '@stateless/atomic/Icon';
import IconColor       from '@stateless/atomic/Icon/IconColor';
import IconButtonStyle from '@stateless/atomic/IconButton/IconButtonStyle';

import styles from './styles.module.scss';

const propTypes = {
    className:            PropTypes.string,
    disabled:             PropTypes.bool,
    iconButtonStyle:      PropTypes.oneOfObjectValues(IconButtonStyle),
    iconColor:            PropTypes.iconColor,
    iconType:             PropTypes.iconType,
    noBottomBorderRadius: PropTypes.bool,
    noLeftBorderRadius:   PropTypes.bool,
    noRightBorderRadius:  PropTypes.bool,
    noTopBorderRadius:    PropTypes.bool,
    onIconButtonClicked:  PropTypes.func,
};

const IconButton = ({
    iconType             = null,
    iconColor            = null,
    iconButtonStyle      = IconButtonStyle.transparent,
    noTopBorderRadius    = false,
    noBottomBorderRadius = false,
    noLeftBorderRadius   = false,
    noRightBorderRadius  = false,
    onIconButtonClicked  = _.noop,
    className            = '',
    disabled             = false,
}) => {
    const getIconColor = () => {
        if (iconColor) {
            return iconColor;
        }

        switch (iconButtonStyle) {
            case IconButtonStyle.blue:
                return IconColor.white;
            case IconButtonStyle.transparent:
                return IconColor.gray;
            default:
                return IconColor.black;
        }
    };

    return (
        <button
            onClick={onIconButtonClicked}
            className={classNames(
                className,
                styles.iconButton,
                {
                    [styles.iconButtonWhite]:       iconButtonStyle === IconButtonStyle.white,
                    [styles.iconButtonBlue]:        iconButtonStyle === IconButtonStyle.blue,
                    [styles.iconButtonTransparent]: iconButtonStyle === IconButtonStyle.transparent,
                    [styles.iconButtonRed]:         iconButtonStyle === IconButtonStyle.red,
                    [styles.iconButtonGreen]:       iconButtonStyle === IconButtonStyle.green,
                    [styles.noTopBorderRadius]:     noTopBorderRadius,
                    [styles.noBottomBorderRadius]:  noBottomBorderRadius,
                    [styles.noLeftBorderRadius]:    noLeftBorderRadius,
                    [styles.noRightBorderRadius]:   noRightBorderRadius,
                },
            )}
            disabled={disabled}
        >
            <Icon
                iconType={iconType}
                iconColor={getIconColor()}
            />
        </button>
    );
};

IconButton.propTypes = propTypes;

export default IconButton;
