//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';
import { useRef }   from 'react';

import Tippy           from '@tippyjs/react';
import classNames      from 'classnames';
import _               from 'lodash';
import { Swiper }      from 'swiper/react';
import { SwiperSlide } from 'swiper/react';

import PropTypes                      from '@components/PropTypes';
import BarGraphSwiperNavigationButton from '@stateless/atomic/BarGraphSwiperNavigationButton';
import NavigationDirection            from '@stateless/atomic/BarGraphSwiperNavigationButton/NavigationDirection';

import styles from './styles.module.scss';

const propTypes = {
    barItems: PropTypes.arrayOf(PropTypes.shape({
        color: PropTypes.string,
        label: PropTypes.string,
    })),
};

const BarGraphSwiper = ({
    barItems = null,
}) => {
    const swiperRef                       = useRef(null);
    const [nextDisabled, setNextDisabled] = useState(false);
    const [prevDisabled, setPrevDisabled] = useState(true);
    const onSlideChanged                  = (swiper) => {
        setNextDisabled(swiper.isEnd);
        setPrevDisabled(swiper.isBeginning);
    };
    const onSwiperLoad                    = (swiper) => {
        swiperRef.current = swiper;
        onSlideChanged(swiper);
    };
    const renderBarLegendText             = (legendText) => {
        if (!legendText) {
            return null;
        }

        return (
            <div className={styles.legendItem}>
                {legendText}
            </div>
        );
    };
    const renderBarGraphSwiperItems       = () => {
        return _.map(barItems, (barItem, index) => {
            const isActive       = barItem.isActive;
            const renderBarParts = () => {
                return _.map(barItem.barParts, (barPart) => {
                    const barStyle = {
                        height:          `${barPart.height}%`,
                        backgroundColor: barPart.color,
                    };

                    return (
                        <div
                            className={styles.barPart}
                            style={barStyle}
                        />
                    );
                });
            };
            const key            = barItem.key ?? `usage_item_${index}`;

            return (
                <SwiperSlide
                    key={key}
                >
                    <Tippy content={barItem.tooltipText}>
                        <div
                            className={classNames(
                                styles.swiperBarContainer,
                                {
                                    [styles.active]: isActive,
                                },
                            )}
                            onClick={barItem.onBarClicked}
                        >
                            {renderBarParts()}
                        </div>
                    </Tippy>
                    {renderBarLegendText(barItem.legendText)}
                </SwiperSlide>
            );
        });
    };

    return (
        <div className={styles.barGraphSwiperWrapper}>
            <BarGraphSwiperNavigationButton
                swiperRef={swiperRef}
                direction={NavigationDirection.previous}
                disabled={prevDisabled}
            />
            <Swiper
                slidesPerView={24}
                centerInsufficientSlides={true}
                spaceBetween={1}
                onSlideChange={onSlideChanged}
                onSwiper={onSwiperLoad}
            >
                {renderBarGraphSwiperItems()}
            </Swiper>
            <BarGraphSwiperNavigationButton
                swiperRef={swiperRef}
                direction={NavigationDirection.next}
                disabled={nextDisabled}
            />
        </div>
    );
};

BarGraphSwiper.propTypes = propTypes;

export default BarGraphSwiper;
