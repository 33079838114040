//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import { useMemo } from 'react';

import _ from 'lodash';

import PropTypes     from '@components/PropTypes';
import IconType      from '@stateless/atomic/Icon/IconType';
import SidebarHeader from '@stateless/atomic/SidebarHeader';

const propTypes = {
    expanded:               PropTypes.bool,
    headline:               PropTypes.string,
    onSidebarToggleClicked: PropTypes.func,
};

const SidebarToggleHeader = ({
    expanded               = false,
    onSidebarToggleClicked = _.noop,
    headline               = '',
}) => {
    const toggleIconType = useMemo(() => {
        return expanded
            ? IconType.cross
            : IconType.burger;
    }, [expanded]);

    return (
        <SidebarHeader
            iconType={toggleIconType}
            headline={headline}
            onIconClicked={onSidebarToggleClicked}
        />
    );
};

SidebarToggleHeader.propTypes = propTypes;

export default SidebarToggleHeader;
