//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes from '@components/PropTypes';
import Icon      from '@stateless/atomic/Icon';
import IconColor from '@stateless/atomic/Icon/IconColor';

import styles from './styles.module.scss';

const propTypes = {
    menuItems: PropTypes.arrayOf(PropTypes.shape({
        label:  PropTypes.string,
        icon:   PropTypes.string,
        action: PropTypes.func,
    })),
    open:      PropTypes.bool,
};

const OverlayMenu = ({
    menuItems = [],
    open      = false,
}) => {
    function renderMenuItems() {
        return menuItems.map((item) => {
            const { label, icon, action: onItemClicked } = item;

            return (
                <button
                    className={styles.overlayMenuItem}
                    key={label}
                    onClick={onItemClicked}
                >
                    <Icon
                        iconType={icon}
                        iconColor={IconColor.gray}
                    />
                    <span>
                        {label}
                    </span>
                </button>
            );
        });
    }

    return (
        <div
            className={classNames(
                styles.overlayMenuComponent,
                {
                    [styles.open]: open,
                },
            )}
        >
            {renderMenuItems()}
        </div>
    );
};

OverlayMenu.propTypes = propTypes;

export default OverlayMenu;
