//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import { useMemo } from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import PropTypes       from '@components/PropTypes';
import CircleIcon      from '@stateless/atomic/CircleIcon';
import CircleIconSize  from '@stateless/atomic/CircleIcon/CircleIconSize';
import CircleIconTheme from '@stateless/atomic/CircleIcon/CircleIconTheme';
import IconType        from '@stateless/atomic/Icon/IconType';
import DetailSidebar   from '@stateless/composed/DetailSidebar';
import SidebarPosition from '@stateless/composed/Sidebar/SidebarPosition';
import SidebarType     from '@stateless/composed/Sidebar/SidebarType';

import styles from './styles.module.scss';

const propTypes = {
    children:                    PropTypes.children,
    detailSidebarContent:        PropTypes.children,
    expanded:                    PropTypes.bool,
    onDetailSidebarCloseClicked: PropTypes.func,
    onSidebarToggleClicked:      PropTypes.func,
    position:                    PropTypes.oneOfObjectValues(SidebarPosition),
    showDetailSidebar:           PropTypes.bool,
    type:                        PropTypes.oneOfObjectValues(SidebarType),
};

const Sidebar = ({
    expanded                    = false,
    onSidebarToggleClicked      = _.noop,
    type                        = SidebarType.fullyCollapsed,
    position                    = SidebarPosition.left,
    children                    = null,
    detailSidebarContent        = null,
    showDetailSidebar           = false,
    onDetailSidebarCloseClicked = _.noop,
}) => {
    const detailsSidebarVisible = useMemo(() => showDetailSidebar && expanded, [showDetailSidebar, expanded]);

    function renderSidebarToggleActionButton() {
        if (type === SidebarType.previewCollapsed) {
            return null;
        }

        return (
            <button
                className={classNames(
                    styles.sidebarToggleActionButton,
                    {
                        [styles.visible]: !expanded,
                        [styles.right]:   position === SidebarPosition.right,
                    },
                )}
                onClick={onSidebarToggleClicked}
            >
                <CircleIcon
                    size={CircleIconSize.big}
                    circleIconTheme={CircleIconTheme.blue}
                    iconType={IconType.search}
                />
            </button>
        );
    }

    return (
        <>
            <div
                className={classNames(
                    styles.sidebarComponent,
                    {
                        [styles.expanded]:        expanded,
                        [styles.fullyCollapsed]:  type === SidebarType.fullyCollapsed,
                        [styles.right]:           position === SidebarPosition.right,
                        [styles.detailsAttached]: showDetailSidebar,
                    },
                )}
            >
                {children}
            </div>
            <DetailSidebar
                visible={detailsSidebarVisible}
                onCloseClicked={onDetailSidebarCloseClicked}
            >
                {detailSidebarContent}
            </DetailSidebar>
            {renderSidebarToggleActionButton()}
        </>
    );
};

Sidebar.propTypes = propTypes;

export default Sidebar;
