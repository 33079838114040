//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import { useCallback } from 'react';
import { useMemo }     from 'react';

import I18n               from 'i18next';
import _                  from 'lodash';
import { useTranslation } from 'react-i18next';
import { Trans }          from 'react-i18next';

import PropTypes               from '@components/PropTypes';
import ContextInformationTab   from '@connected/ContextInformationPanel/ContextInformationTab';
import SelectedDateRangeLabel  from '@connected/SelectedDateRangeLabel';
import DateTime                from '@helper/DateTime';
import DurationHelper          from '@helper/DurationHelper';
import withContextPanelPadding from '@hoc/ContextPanelPadding';
import useContextLoader        from '@hooks/useContextLoader';
import ContextSlotLabel        from '@stateless/atomic/ContextSlotLabel';
import IconType                from '@stateless/atomic/Icon/IconType';
import IconTextRow             from '@stateless/atomic/IconTextRow';
import UppercaseLabel          from '@stateless/atomic/UppercaseLabel';
import BarGraphSwiper          from '@stateless/composed/BarGraphSwiper';
import ContextTitle            from '@stateless/composed/ContextTitle';
import Colors                  from '@styles/colors.scss';

import styles from './styles.module.scss';

const propTypes = {
    context: PropTypes.object,
};

const ContextPunctuality = ({
    context = null,
}) => {
    const { t }                                        = useTranslation(null, {
        keyPrefix: 'components.context',
    });
    const { setSelectedBarIndex, getSelectedBarIndex } = useContextLoader();
    const resolution                                   = context?.punctuality?.resolution;
    const contextData                                  = context?.punctuality?.data;
    const selectedBarIndex                             = getSelectedBarIndex(ContextInformationTab.punctuality);
    const inspectedPunctualityPart                     = useMemo(() => {
        return contextData?.[selectedBarIndex] ?? null;
    }, [selectedBarIndex]);
    const slotDateTime                                 = inspectedPunctualityPart?.slot;
    const getPunctualityText                           = () => {
        const totalUsage            = _.reduce(contextData, (sum, punctualityItem) => {
            return sum + punctualityItem.usage;
        }, 0);
        const percentageLate        = _.reduce(contextData, (sum, punctualityItem) => {
            return sum + (
                punctualityItem.usage * parseFloat(punctualityItem.percentageLate)
            );
        }, 0.0);
        const averagePercentageLate = totalUsage > 0
            ? percentageLate / totalUsage
            : 0;

        return (
            <Trans
                t={t}
                i18nKey={'averagePunctualityStop'}
                values={{
                    usage:           totalUsage,
                    percentageDelay: averagePercentageLate,
                }}
            />
        );
    };
    const createPunctualityBarItems                    = () => {
        if (!contextData) {
            return null;
        }

        return _.map(contextData, (punctualityItem, index) => {
            const key = `${context.overview.id}_${index}_${resolution}`;

            return {
                key,
                barParts:     [
                    {
                        color:  Colors.red,
                        height: punctualityItem.percentageLate,
                    },
                ],
                onBarClicked: () => {
                    setSelectedBarIndex(ContextInformationTab.punctuality, index);
                },
                tooltipText:  DateTime.getDateTimeStringByResolution(punctualityItem.slot, resolution),
                isActive:     index === selectedBarIndex,
                legendText:   DateTime.getDateTimeLegendInfoByResolution(punctualityItem.slot, resolution),
            };
        });
    };
    const renderDetailParts                            = useCallback(() => {
        const detailParts = [
            {
                text:  t('rides'),
                value: inspectedPunctualityPart.usage,
            },
            {
                text:      t('delay'),
                value:     `${DurationHelper.secondsToMinutes(inspectedPunctualityPart.delay)} ${I18n.t('minutesShort')} ${t('percentualDelay', {
                    percentage: inspectedPunctualityPart.percentageLate,
                })}`,
                className: styles.delay,
            },
        ];

        return _.map(detailParts, (detailPart) => {
            return (
                <div key={detailPart.text}>
                    <UppercaseLabel text={detailPart.text} />
                    <span className={detailPart.className}>
                        {detailPart.value}
                    </span>
                </div>
            );
        });
    }, [inspectedPunctualityPart]);
    const renderPunctualityPartDetails                 = () => {
        if (!inspectedPunctualityPart) {
            return null;
        }

        return (
            <div className={styles.punctualityDetailsWrapper}>
                <ContextSlotLabel
                    resolution={resolution}
                    slotDateTime={slotDateTime}
                />
                <div>
                    {renderDetailParts()}
                </div>
            </div>
        );
    };

    return (
        withContextPanelPadding(<div className={styles.contextPunctualityComponent}>
            <div className={styles.headerRow}>
                <ContextTitle
                    text={t('punctuality')}
                />
                <SelectedDateRangeLabel />
            </div>
            <IconTextRow
                text={getPunctualityText()}
                iconType={IconType.punctuality}
            />
            <div className={styles.punctualitySwiperWrapper}>
                <BarGraphSwiper barItems={createPunctualityBarItems()} />
            </div>
            {renderPunctualityPartDetails()}
        </div>)
    );
};

ContextPunctuality.propTypes = propTypes;

export default ContextPunctuality;
