//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { LOCATION_CHANGE } from 'connected-react-router';
import { REHYDRATE }       from 'redux-persist';
import { all }             from 'redux-saga/effects';
import { takeLatest }      from 'redux-saga/effects';
import { takeEvery }       from 'redux-saga/effects';

import { BrowserActions }               from '@slices/browser';
import { LoadingActions }               from '@slices/loading';
import { NavigationActions }            from '@slices/navigation';
import { StagingAuthenticationActions } from '@slices/stagingAuthentication';
import { UserActions }                  from '@slices/user';
import { WindowActions }                from '@slices/window';

import BrowserSagas               from './browser';
import LoadingSagas               from './loading';
import NavigationSagas            from './navigation';
import PreLoadSagas               from './preload';
import StagingAuthenticationSagas from './stagingAuthentication';
import UserSagas                  from './user';
import WindowSagas                from './window';

const callBrowserSagas = () => {
    return [
        // @formatter:off
        takeLatest([BrowserActions.scrollToTop().type],              BrowserSagas.scrollToTop),
        takeLatest([BrowserActions.scrollToTopReactDataGrid().type], BrowserSagas.scrollToTopReactDataGrid),
        // @formatter:on
    ];
};

const callStagingAuthenticationSagas = () => {
    return [
        // @formatter:off
        takeLatest([NavigationActions.resetState().type],                    StagingAuthenticationSagas.reset),
        takeLatest([StagingAuthenticationActions.authenticate().type],       StagingAuthenticationSagas.authenticate),
        takeLatest([StagingAuthenticationActions.authenticateFailed().type], StagingAuthenticationSagas.authenticateFailed),
        // @formatter:on
    ];
};

const callUserSagas = () => {
    return [
        // @formatter:off
        takeLatest([UserActions.login().type],                UserSagas.login),
        takeLatest([UserActions.loginWithCredentials().type], UserSagas.loginWithCredentials),
        takeLatest([UserActions.loginFailed().type],          UserSagas.loginFailed),
        takeEvery([UserActions.loginSucceeded().type],        UserSagas.loginSucceeded),
        takeLatest([UserActions.tryRestoreToken().type],      UserSagas.restoreToken),
        takeLatest([UserActions.logout().type],               UserSagas.logout),
        takeLatest([NavigationActions.resetState().type],     UserSagas.logout),
        // @formatter:on
    ];
};

const callLoadingSagas = () => {
    return [
        // @formatter:off
        takeLatest([LoadingActions.overlayClicked().type], LoadingSagas.overlayClicked),
        // @formatter:on
    ];
};

const callNavigationSagas = () => {
    return [
        // @formatter:off
        takeLatest([NavigationActions.redirect().type], NavigationSagas.redirect),
        // @formatter:on
    ];
};

const callLocationChangeSagas = () => {
    return [
        // @formatter:off
        takeLatest([LOCATION_CHANGE], NavigationSagas.locationChange),
        takeLatest([LOCATION_CHANGE], LoadingSagas.resetClickCount),
        // @formatter:on
    ];
};

const callWindowSagas = () => {
    return [
        // @formatter:off
        takeLatest([WindowActions.windowBecameVisible().type], WindowSagas.windowBecameVisible),
        takeLatest([WindowActions.windowGotFocus().type],      WindowSagas.windowGotFocus),
        // @formatter:on
    ];
};

const callRehydrateSagas = () => {
    return [
        // @formatter:off
        takeLatest([REHYDRATE], LoadingSagas.rehydrate),
        takeLatest([REHYDRATE], PreLoadSagas.preLoadData),
        // @formatter:on
    ];
};

function* root() {
    yield all([
        ...callBrowserSagas(),
        ...callStagingAuthenticationSagas(),
        ...callUserSagas(),
        ...callLoadingSagas(),
        ...callNavigationSagas(),
        ...callLocationChangeSagas(),
        ...callWindowSagas(),
        ...callRehydrateSagas(),
    ]);
}

export default {
    root,
};
