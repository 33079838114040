//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

export const selectSelectedContext = (state) => {
    const selectedContext = _.get(state, 'map.selectedContext');

    if (
        !selectedContext ||
        !selectedContext.id ||
        !selectedContext.type
    ) {
        return null;
    }

    return selectedContext;
};

export default selectSelectedContext;
