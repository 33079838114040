//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _                  from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector }    from 'react-redux';
import { useDispatch }    from 'react-redux';

import ConnectionDetailType    from '@constants/ConnectionDetailType';
import ContextType             from '@constants/ContextType';
import QuickViewType           from '@constants/QuickViewType';
import SearchMode              from '@constants/SearchMode';
import DateTime                from '@helper/DateTime';
import useConnectionsViewApi   from '@hooks/useConnectionsViewApi';
import useContextLoader        from '@hooks/useContextLoader';
import { useQuickViewActions } from '@slices/quickView';
import IconType                from '@stateless/atomic/Icon/IconType';
import SidebarConnectionItem   from '@stateless/atomic/SidebarConnectionItem';
import SkeletonItem            from '@stateless/atomic/SkeletonItem';
import SkeletonItemType        from '@stateless/atomic/SkeletonItem/SkeletonItemType';
import SidebarStopDetailItem   from '@stateless/composed/SidebarStopDetailItem';

import styles from './styles.module.scss';

const propTypes = {};

const connectionDetailTypeMapping = {
    [QuickViewType.unreliableTransits]: ConnectionDetailType.changeTime,
    [QuickViewType.reliableTransits]:   ConnectionDetailType.changeTime,
    [QuickViewType.mostTransits]:       ConnectionDetailType.personCount,
    [QuickViewType.leastTransits]:      ConnectionDetailType.personCount,
};

const QuickViewContent = () => {
    const activeQuickActionType              = useSelector((state) => state.quickView.activeQuickViewType);
    const searchMode                         = useSelector((state) => state.quickView.searchMode);
    const connectionDetailType               = connectionDetailTypeMapping[activeQuickActionType];
    const { data, isFetching, error }        = useConnectionsViewApi();
    const dispatch                           = useDispatch();
    const { loadInContext }                  = useContextLoader();
    const { setShowQuickViewSidebarDetails } = useQuickViewActions(dispatch);
    const { t }                              = useTranslation(null, {
        keyPrefix: 'components.action',
    });
    const closeSidebarDetails                = () => {
        setShowQuickViewSidebarDetails({
            showQuickViewSidebarDetails: false,
        });
    };

    function getChangeDetailNumbers(transitTime) {
        let changeDetailNumber     = 0;
        let changeDetailRealNumber = 0;
        const transitTimeCount     = _.get(transitTime, 'count', 0);

        if (transitTime) {
            if (connectionDetailType === ConnectionDetailType.personCount) {
                changeDetailNumber = transitTimeCount;
            } else if (connectionDetailType === ConnectionDetailType.changeTime) {
                changeDetailNumber     = DateTime.durationStringToMinutes(transitTime.expectedTransitTime);
                changeDetailRealNumber = DateTime.durationStringToMinutes(transitTime.actualTransitTime);
            }
        }

        return {
            changeDetailNumber,
            changeDetailRealNumber,
        };
    }

    function calculatePercentageDelay(routes) {
        if (
            connectionDetailType === ConnectionDetailType.personCount ||
            _.isEmpty(routes)
        ) {
            return 0;
        }

        const totalChangeTimeExpected = _.sumBy(routes, 'changeDetailNumber');
        const totalChangeTimeReal     = _.sumBy(routes, 'changeDetailRealNumber');

        // @formatter:off
        return Math.floor((totalChangeTimeReal / totalChangeTimeExpected) * 100);
        // @formatter:on
    }

    const renderConnections     = () => {
        if (_.isEmpty(data)) {
            return null;
        }

        return _.map(data, (transit, index) => {
            const transitConnections  = _.get(transit, 'connection', []);
            const firstConnection     = _.first(transitConnections);
            const firstStopName       = _.get(firstConnection, 'from.nameWithLocation', '');
            const lastConnection      = _.last(transitConnections);
            const lastStopName        = _.get(lastConnection, 'to.nameWithLocation', '');
            const numberOfRoutes      = transit.connection.length;
            const percentageWidth     = numberOfRoutes !== 0
                ? 100 / numberOfRoutes
                : 0;
            const routes              = _.map(transitConnections, (connection) => {
                const toStopId                                       = connection?.to?.id;
                const transitTime                                    = _.find(transit.transitTimes, (transitTimeItem) => {
                    return transitTimeItem.transitStop === toStopId;
                });
                const routeId                                        = connection.routeId;
                const onRouteClicked                                 = () => {
                    if (!routeId) {
                        return;
                    }

                    loadInContext({
                        id:   routeId,
                        type: ContextType.route,
                    });
                    closeSidebarDetails();
                };
                const { changeDetailNumber, changeDetailRealNumber } = getChangeDetailNumbers(transitTime);

                return {
                    percentageWidth,
                    name:           _.get(connection, 'line', ''),
                    color:          _.get(connection, 'color', ''),
                    changeStopName: _.get(connection, 'to.nameWithoutLocation', ''),
                    changeDetailNumber,
                    changeDetailRealNumber,
                    onRouteClicked,
                    disabled:       !routeId,
                };
            });
            const percentageDelay     = calculatePercentageDelay(routes);
            const showPercentageDelay = connectionDetailType === ConnectionDetailType.changeTime;

            return (
                <SidebarConnectionItem
                    key={index}
                    fromStopName={firstStopName}
                    toStopName={lastStopName}
                    connectionDetailType={connectionDetailType}
                    percentageDelay={percentageDelay}
                    routes={routes}
                    showPercentageDelay={showPercentageDelay}
                />
            );
        });
    };
    const renderStops           = () => {
        if (_.isEmpty(data)) {
            return null;
        }

        return _.map(data, (stopItem, index) => {
            const stopName          = _.get(stopItem, 'stop.nameWithoutLocation', '');
            const count             = _.get(stopItem, 'count', 0);
            const stopId            = _.get(stopItem, 'stop.id', '');
            const onStopItemClicked = () => {
                loadInContext({
                    id:   stopId,
                    type: ContextType.stop,
                });
                closeSidebarDetails();
            };

            return (
                <SidebarStopDetailItem
                    key={index}
                    iconType={IconType.bus}
                    title={stopName}
                    personCount={count}
                    isFavorite={false}
                    onItemClicked={onStopItemClicked}
                />
            );
        });
    };
    const renderLoadingSkeleton = () => {
        let type = null;

        switch (searchMode) {
            case SearchMode.routes:
                type = SkeletonItemType.connectionItem;

                break;
            case SearchMode.stops:
                type = SkeletonItemType.stopItem;

                break;
        }

        return _.times(
            4,
            () => <SkeletonItem
                type={type}
            />,
        );
    };
    const renderNoResults       = () => {
        return (
            <div className={styles.textContent}>
                <p>
                    {t('noResults')}
                </p>
            </div>

        );
    };
    const renderErrorMessage    = () => {
        return (
            <div className={styles.textContent}>
                <p>
                    {t('error')}
                </p>
            </div>
        );
    };

    if (isFetching) {
        return renderLoadingSkeleton();
    }

    if (_.isEmpty(data)) {
        return renderNoResults();
    }

    if (error) {
        return renderErrorMessage();
    }

    if (searchMode === SearchMode.routes) {
        return renderConnections();
    }

    return renderStops();
};

QuickViewContent.propTypes = propTypes;

export default QuickViewContent;
