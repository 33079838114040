//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put } from 'redux-saga/effects';

import Notification                     from '@helper/Notification';
import SagaStateHelper                  from '@helper/SagaStateHelper';
import { StagingAuthenticationActions } from '@slices/stagingAuthentication';

const stagingPassword = 'grgsG6RxZo6Gm39ke4YH';

function* authenticate() {
    const passwordFromState = yield SagaStateHelper.selectFromState('stagingAuthentication', 'password');
    const isAuthenticated   = stagingPassword === passwordFromState;

    if (isAuthenticated) {
        yield put(StagingAuthenticationActions.authenticateSucceeded({
            isAuthenticated,
        }));
    } else {
        yield put(StagingAuthenticationActions.authenticateFailed());
    }
}

function* authenticateFailed() {
    Notification.error('wrongStagingPassword');
}

function* reset() {
    yield put(StagingAuthenticationActions.reset());
}

export default {
    authenticate,
    authenticateFailed,
    reset,
};
