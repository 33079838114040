//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';

import Headline from '@stateless/atomic/Headline';

import styles from './styles.module.scss';

const ScreenTooSmallOverlay = () => {
    return (
        <div
            aria-hidden={'true'}
            className={styles.screenTooSmallOverlay}
        >
            <div className={styles.screenTooSmallOverlayContent}>
                <Headline title={I18n.t('screenTooSmallTitle')} />
                <p>
                    {I18n.t('screenTooSmallText')}
                </p>
            </div>
        </div>
    );
};

export default ScreenTooSmallOverlay;
