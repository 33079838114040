//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import update                 from 'immutability-helper';
import { bindActionCreators } from 'redux';

const initialState = Object.freeze({
    contextHistory: [],
});

const NUMBER_OF_HISTORY_ITEMS = 3;

const historySlice = createSlice({
    name:     'history',
    initialState,
    reducers: {
        addContextHistory:        (state, action) => {
            const { context } = action.payload;
            const newState    = update(state, {
                contextHistory: {
                    $push: [context],
                },
            });

            if (newState.contextHistory.length > NUMBER_OF_HISTORY_ITEMS) {
                newState.contextHistory.shift();
            }

            return newState;
        },
        clearHistory:             (state) => {
            return update(state, {
                $set: initialState,
            });
        },
        setActiveContextHistory:  (state, action) => {
            const { hash } = action.payload;
            const newState = update(state, {
                contextHistory: {
                    $apply: (history) => history.map((item) => {
                        return update(item, {
                            isActive: {
                                $set: item.hash === hash,
                            },
                        });
                    }),
                },
            });

            return newState;
        },
        deactivateContextHistory: (state) => {
            return update(state, {
                contextHistory: {
                    $apply: (history) => history.map((item) => {
                        return update(item, {
                            isActive: {
                                $set: false,
                            },
                        });
                    }),
                },
            });
        },
        updateContextHistory:     (state, action) => {
            const { context } = action.payload;
            const newState    = update(state, {
                contextHistory: {
                    $apply: (history) => history.map((item) => {
                        if (item.hash === context.hash) {
                            return context;
                        }

                        return item;
                    }),
                },
            });

            return newState;
        },
    },
});

export const HistoryActions = historySlice.actions;

export const HistoryReducer = historySlice.reducer;

export const useHistoryActions = (dispatch) => bindActionCreators(HistoryActions, dispatch);

export default historySlice;
