//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import PropTypes     from '@components/PropTypes';
import IconType      from '@stateless/atomic/Icon/IconType';
import SidebarHeader from '@stateless/atomic/SidebarHeader';
import Spacer        from '@stateless/atomic/Spacer';

import styles from './styles.module.scss';

const propTypes = {
    children:       PropTypes.children,
    onCloseClicked: PropTypes.func,
    visible:        PropTypes.bool,
};

const DetailSidebar = ({
    children       = null,
    visible        = false,
    onCloseClicked = _.noop,
}) => {
    return (
        <div
            className={classNames(
                styles.detailSidebarComponent,
                {
                    [styles.visible]: visible,
                },
            )}
        >
            <div className={styles.closeButtonWrapper}>
                <SidebarHeader
                    iconType={IconType.cross}
                    onIconClicked={onCloseClicked}
                />
            </div>
            <Spacer height={50} />
            {children}
        </div>
    );
};

DetailSidebar.propTypes = propTypes;

export default DetailSidebar;
