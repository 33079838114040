//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames         from 'classnames';
import _                  from 'lodash';
import { useTranslation } from 'react-i18next';

import PropTypes           from '@components/PropTypes';
import SidebarQuickActions from '@connected/SidebarQuickActions';
import SidebarSearchForm   from '@connected/SidebarSearchForm';
import Spacer              from '@stateless/atomic/Spacer';
import SidebarToggleHeader from '@stateless/composed/SidebarToggleHeader';

import styles from './styles.module.scss';

const propTypes = {
    expanded:               PropTypes.bool,
    onSidebarToggleClicked: PropTypes.func,
};

const SidebarMapSearchContent = ({
    expanded               = false,
    onSidebarToggleClicked = _.noop,
}) => {
    const { t } = useTranslation(null, {
        keyPrefix: 'components.search',
    });

    return (
        <div
            className={classNames(
                styles.sidebarMapSearchComponent,
                {
                    [styles.visible]: expanded,
                },
            )}
        >
            <SidebarToggleHeader
                expanded={true}
                onSidebarToggleClicked={onSidebarToggleClicked}
                headline={t('search')}
            />
            <Spacer height={50} />
            <SidebarSearchForm />
            <SidebarQuickActions />
        </div>
    );
};

SidebarMapSearchContent.propTypes = propTypes;

export default SidebarMapSearchContent;
