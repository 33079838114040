//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes from '@components/PropTypes';
import Icon      from '@stateless/atomic/Icon';
import IconColor from '@stateless/atomic/Icon/IconColor';

import styles from './styles.module.scss';

const propTypes = {
    iconColor:    PropTypes.iconColor,
    iconType:     PropTypes.iconType,
    rightContent: PropTypes.children,
    text:         PropTypes.string,
};

const SidebarSeparator = ({
    text         = '',
    iconType     = null,
    iconColor    = IconColor.black,
    rightContent = null,
}) => {
    return (
        <div
            className={styles.sidebarSeparatorComponent}
        >
            <span className={styles.separatorText}>
                {text}
            </span>
            <Icon
                iconType={iconType}
                iconColor={iconColor}
            />
            {rightContent}
        </div>
    );
};

SidebarSeparator.propTypes = propTypes;

export default SidebarSeparator;
