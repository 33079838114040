//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import PropTypes           from '@components/PropTypes';
import RouteType           from '@constants/RouteType';
import StopType            from '@constants/StopType';
import CircleIcon          from '@stateless/atomic/CircleIcon';
import CircleIconTheme     from '@stateless/atomic/CircleIcon/CircleIconTheme';
import IconType            from '@stateless/atomic/Icon/IconType';
import ShortNameLabelGroup from '@stateless/atomic/ShortNameLabelGroup';
import SidebarListItem     from '@stateless/composed/SidebarListItem';

import styles from './styles.module.scss';

const propTypes = {
    onItemClicked: PropTypes.func,
    result:        PropTypes.shape({
        stopType: PropTypes.oneOfObjectValues(StopType),
        name:     PropTypes.string,
        region:   PropTypes.string,
        routes:   PropTypes.array,
    }),
};

const SearchResultListItem = ({
    result        = null,
    onItemClicked = _.noop,
}) => {
    function renderIconView() {
        const icon = result.stopType === StopType.busStop
            ? IconType.bus
            : IconType.train;

        return (
            <CircleIcon
                iconType={icon}
                circleIconTheme={CircleIconTheme.yellow}
            />
        );
    }

    function renderRouteGroup(routeType) {
        const icon   = RouteType.busRoute === routeType
            ? IconType.bus
            : IconType.train;
        const routes = _.filter(result.routes, (route) => route.type === routeType);

        if (_.isEmpty(routes)) {
            return null;
        }

        const cleanedRoutes     = _.filter(routes, (route) => !_.isNil(route.shortName));
        const transformedRoutes = _.map(cleanedRoutes, (route) => (
            {
                text:  route.shortName,
                color: route.color,
            }
        ));

        return (
            <ShortNameLabelGroup
                iconType={icon}
                labelItems={transformedRoutes}
            />
        );
    }

    function renderRouteGroups() {
        if (_.isEmpty(result.routes)) {
            return null;
        }

        return (
            <div className={styles.routesWrapper}>
                {renderRouteGroup(RouteType.trainRoute)}
                {renderRouteGroup(RouteType.busRoute)}
            </div>
        );
    }

    function renderContentView() {
        return (
            <div className={styles.stopContentView}>
                <span className={styles.stopName}>
                    {result.name}
                </span>
                <span className={styles.stopRegion}>
                    {result.region}
                </span>
                {renderRouteGroups()}
            </div>
        );
    }

    return (
        <SidebarListItem
            iconView={renderIconView()}
            titleView={renderContentView()}
            smallPadding={true}
            onItemClicked={onItemClicked}
        />
    );
};

SearchResultListItem.propTypes = propTypes;

export default SearchResultListItem;
