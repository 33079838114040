//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import moment             from 'moment';
import { useTranslation } from 'react-i18next';

import PropTypes          from '@components/PropTypes';
import BarGraphResolution from '@constants/BarGraphResolution';

import styles from './styles.module.scss';

const propTypes = {
    resolution:   PropTypes.oneOfObjectValues(BarGraphResolution),
    slotDateTime: PropTypes.string,
};

const ContextSlotLabel = ({
    resolution   = null,
    slotDateTime = 'null',
}) => {
    const { t }            = useTranslation(null, {
        keyPrefix: 'components.context',
    });
    const getSlotLabelText = () => {
        const slotMoment   = moment(slotDateTime);
        const date         = slotMoment.format('DD.MM.yyyy');
        const monthAndYear = slotMoment.format('MMMM YYYY');
        const time         = slotMoment.format('HH:mm');

        switch (resolution) {
            case BarGraphResolution.hours:
                return t('slotLabelHour', {
                    date,
                    time,
                });
            case BarGraphResolution.days:
                return t('slotLabelDay', {
                    date,
                });
            case BarGraphResolution.months:
                return t('slotLabelMonth', {
                    monthAndYear,
                });
        }

        return null;
    };

    return (
        <div
            className={styles.contextSlotLabel}
        >
            {getSlotLabelText()}:
        </div>
    );
};

ContextSlotLabel.propTypes = propTypes;

export default ContextSlotLabel;
