//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames         from 'classnames';
import _                  from 'lodash';
import { useTranslation } from 'react-i18next';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    leftOption:      PropTypes.string,
    onOptionClicked: PropTypes.func,
    rightOption:     PropTypes.string,
    selectedOption:  PropTypes.string,
    width:           PropTypes.number,
};

const LabeledSwitch = ({
    leftOption      = null,
    rightOption     = null,
    onOptionClicked = _.noop,
    selectedOption  = null,
    width           = 200,
}) => {
    const { t }             = useTranslation(null, {
        keyPrefix: 'components.search',
    });
    const wrapperWidthStyle = {
        width,
    };

    function renderOptions() {
        const options = [
            leftOption,
            rightOption,
        ];

        return _.map(options, (option) => {
            const isSelected = option === selectedOption;

            function onClicked() {
                onOptionClicked(option);
            }

            return (
                <div
                    key={option}
                    onClick={onClicked}
                    className={classNames(
                        styles.option,
                        {
                            [styles.selected]: isSelected,
                        },
                    )}
                >
                    {t(option)}
                </div>
            );
        });
    }

    return (
        <div className={styles.labeledSwitchComponent}>
            <div
                className={styles.labeledSwitchWrapper}
                style={wrapperWidthStyle}
            >
                <div className={styles.optionsWrapper}>
                    {renderOptions()}
                </div>
                <div
                    className={classNames(
                        styles.toggle,
                        {
                            [styles.right]: selectedOption === rightOption,
                        },
                    )}
                />
            </div>
        </div>
    );
};

LabeledSwitch.propTypes = propTypes;

export default LabeledSwitch;
