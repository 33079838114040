//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import { useMemo } from 'react';

import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';

import Icon               from '@stateless/atomic/Icon';
import IconType           from '@stateless/atomic/Icon/IconType';
import SidebarLabeledItem from '@stateless/atomic/SidebarLabeledItem';

import styles from './styles.module.scss';

const propTypes = {
    routeFromStopName: PropTypes.string,
    routeToStopName:   PropTypes.string,
};

const SidebarConnectionHeader = ({
    routeFromStopName = '',
    routeToStopName   = '',
}) => {
    const { t }                    = useTranslation(null, {
        keyPrefix: 'components.search',
    });
    const routeResultHeaderContent = useMemo(() => (
        <div className={styles.routeResultHeaderContent}>
            <span className={styles.stopName}>
                {routeFromStopName}
            </span>
            <Icon iconType={IconType.arrowRight} />
            <span className={styles.stopName}>
                {routeToStopName}
            </span>
        </div>
    ), [routeFromStopName, routeToStopName]);

    return (
        <SidebarLabeledItem
            content={routeResultHeaderContent}
            label={t('connection')}
        />
    );
};

SidebarConnectionHeader.propTypes = propTypes;

export default SidebarConnectionHeader;
