//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes from '@components/PropTypes';
import Icon      from '@stateless/atomic/Icon';
import IconType  from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {
    centerContent: PropTypes.children,
    leftContent:   PropTypes.children,
    rightContent:  PropTypes.children,
};

const Header = ({
    leftContent   = null,
    centerContent = null,
    rightContent  = null,
}) => {
    function renderLeftContent() {
        if (leftContent) {
            return leftContent;
        }

        return (
            <Icon iconType={IconType.logo} />
        );
    }

    function renderRightContent() {
        if (rightContent) {
            return rightContent;
        }

        // TODO: https://lulububu.atlassian.net/browse/BODOBIG-530
        return null;
    }

    return (
        <div className={styles.headerComponent}>
            <div className={styles.leftContent}>
                {renderLeftContent()}
            </div>
            <div className={styles.centerContent}>
                {centerContent}
            </div>
            <div className={styles.rightContent}>
                {renderRightContent()}
            </div>
        </div>
    );
};

Header.propTypes = propTypes;

export default Header;
