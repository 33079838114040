//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import * as Leaflet       from 'leaflet';
import { renderToString } from 'react-dom/server';

import PropTypes       from '@components/PropTypes';
import CircleIconSize  from '@stateless/atomic/CircleIcon/CircleIconSize';
import CircleIconTheme from '@stateless/atomic/CircleIcon/CircleIconTheme';
import MapPin          from '@stateless/atomic/MapPin';
import MarkerCluster   from '@stateless/atomic/MarkerCluster';

const propTypes = {
    markers: PropTypes.array,
};

const StopMarkerCluster = ({
    markers = [],
}) => {
    const renderClusterPin = (cluster) => {
        const childCount    = cluster.getChildCount();
        const component     = (
            <MapPin
                text={childCount}
                circleIconSize={CircleIconSize.default}
                circleTheme={CircleIconTheme.yellow}
            />
        );
        const componentHtml = renderToString(component);

        return Leaflet.divIcon({
            html: `<div>${componentHtml}</div>`,
        });
    };

    return (
        <MarkerCluster
            markers={markers}
            renderClusterPin={renderClusterPin}
        />
    );
};

StopMarkerCluster.propTypes = propTypes;

export default StopMarkerCluster;
