//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

class Distance {
    /* because calculations are like this */

    /* eslint-disable  */
    static calculateDistanceInMeterBetweenTwoCoordinates(latitude1, longitude1, latitude2, longitude2) {
        const EARTH_RADIUS_KM   = 6378.137;
        const lat1InRadians     = latitude1 * Math.PI / 180;
        const lat2InRadians     = latitude2 * Math.PI / 180;
        const deltaLatInRadians = (
            latitude2 - latitude1
        ) * Math.PI / 180;
        const deltaLonInRadians = (
            longitude2 - longitude1
        ) * Math.PI / 180;
        const firstPoint        = Math.sin(deltaLatInRadians / 2) * Math.sin(deltaLatInRadians / 2) +
            Math.cos(lat1InRadians) * Math.cos(lat2InRadians) *
            Math.sin(deltaLonInRadians / 2) * Math.sin(deltaLonInRadians / 2);
        const secondPoint       = 2 * Math.atan2(Math.sqrt(firstPoint), Math.sqrt(1 - firstPoint));
        const distanceInKm      = EARTH_RADIUS_KM * secondPoint;
        const distanceInMeters  = distanceInKm * 1000;

        return distanceInMeters;
    }

    /* eslint-enable  */
}

export default Distance;
