//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import StopType from '@constants/StopType';
import IconType from '@stateless/atomic/Icon/IconType';

class ContextHelper {
    static getStopTypeByTypes(defaultType, types) {
        let type = defaultType;

        if (_.includes(types, StopType.trainStation)) {
            type = StopType.trainStation;
        } else if (_.includes(types, StopType.ferryStation)) {
            type = StopType.ferryStation;
        } else if (_.includes(types, StopType.busStop)) {
            type = StopType.busStop;
        }

        return type;
    }

    static getIconTypeByStopType(stopType) {
        switch (stopType) {
            case StopType.trainStation:
                return IconType.train;
            case StopType.ferryStation:
                return IconType.ferry;
            case StopType.busStop:
            default:
                return IconType.bus;
        }
    }

    static shouldDisplayPunctualityNoData(context) {
        if (!context?.punctuality?.data?.length) {
            return true;
        }

        for (const punctualityItem of context.punctuality.data) {
            if (punctualityItem.usage > 0) {
                return false;
            }
        }

        return true;
    }

    static shouldDisplayPassengerVolumeNoData(context) {
        if (
            !context?.usage?.data?.length ||
            !context?.usage?.sums?.total
        ) {
            return true;
        }

        return false;
    }
}

export default ContextHelper;
