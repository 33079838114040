//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push }  from 'connected-react-router';
import _         from 'lodash';
import { put }   from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';

import Routes                from '@constants/Routes';
import SagaStateHelper       from '@helper/SagaStateHelper';
import { BrowserActions }    from '@slices/browser';
import { NavigationActions } from '@slices/navigation';

function* locationChange() {
    const route = SagaStateHelper.selectFromState(
        'router',
        'location',
        'pathname',
    );

    if (route === Routes.login) {
        yield put(NavigationActions.resetState());
    }

    yield put(BrowserActions.scrollToTop());
}

function* redirect(action) {
    const payload       = _.get(action, 'payload', {});
    const redirectDelay = _.get(payload, 'delay', 0);

    if (redirectDelay > 0) {
        yield delay(redirectDelay);
    }

    yield put(push(payload.route));
}

export default {
    locationChange,
    redirect,
};
