//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import { useMemo } from 'react';

import classNames from 'classnames';
import _          from 'lodash';
import moment     from 'moment';

import PropTypes            from '@components/PropTypes';
import { DateRangeDefault } from '@helper/DateRange';
import DateRangeHelper      from '@helper/DateRange';

import styles from './styles.module.scss';

const propTypes = {
    selectedDateRange: PropTypes.object,
    setSelectedDate:   PropTypes.func,
};

const NUMBER_OF_DAYS = 11;

const TimeBeam = ({
    selectedDateRange = DateRangeDefault,
    setSelectedDate   = _.noop,
}) => {
    const selectedDateString = useMemo(() => {
        if (DateRangeHelper.isSingleDate(selectedDateRange)) {
            return moment(selectedDateRange.from).format('YYYY-MM-DD');
        }

        return null;
    }, [selectedDateRange]);
    const dateList           = useMemo(() => {
        const dates            = [];
        const today            = moment();
        const relativeDate     = selectedDateString
            ? moment(selectedDateString)
            : today.clone();
        const daysBeforeCenter = Math.floor(NUMBER_OF_DAYS / 2);
        let beamEndDate        = relativeDate.add(daysBeforeCenter, 'days');

        if (beamEndDate >= today) {
            beamEndDate = today;
        }

        _.times(NUMBER_OF_DAYS, (index) => {
            const date = beamEndDate.clone().subtract(index, 'days');

            dates.push(date.format('YYYY-MM-DD'));
        });

        return dates.reverse();
    }, [selectedDateString]);

    function onDateSelectClicked(date) {
        return () => {
            setSelectedDate(date);
        };
    }

    function renderTimeBeamItems() {
        return dateList.map((date) => {
            const dayString = moment(date).format('DD.MM.');
            const selected  = date === selectedDateString;

            return (
                <button
                    className={classNames(
                        styles.timeBeamItem,
                        {
                            [styles.selected]: selected,
                        },
                    )}
                    key={date}
                    onClick={onDateSelectClicked(date)}
                >
                    {dayString}
                </button>
            );
        });
    }

    return (
        <div className={styles.timeBeamComponent}>
            {renderTimeBeamItems()}
        </div>
    );
};

TimeBeam.propTypes = propTypes;

export default TimeBeam;
