//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useDispatch } from 'react-redux';

import { HistoryActions } from '@slices/history';
import calendarApi        from '@store/api/calendar';
import connectionsViewApi from '@store/api/connectionsView';
import contextApi         from '@store/api/context';
import polylineApi        from '@store/api/polyline';
import polylinesApi       from '@store/api/polylines';
import regionApi          from '@store/api/region';
import routesApi          from '@store/api/routes';
import stopsApi           from '@store/api/stops';
import tripsApi           from '@store/api/trips';

const useStoreCleanup = () => {
    const dispatch                = useDispatch();
    const cleanupApiStateAndStore = () => {
        dispatch(regionApi.util.resetApiState());
        dispatch(polylineApi.util.resetApiState());
        dispatch(polylinesApi.util.resetApiState());
        dispatch(connectionsViewApi.util.resetApiState());
        dispatch(contextApi.util.resetApiState());
        dispatch(stopsApi.util.resetApiState());
        dispatch(calendarApi.util.resetApiState());
        dispatch(tripsApi.util.resetApiState());
        dispatch(routesApi.util.resetApiState());
        dispatch(HistoryActions.clearHistory());
    };

    return {
        cleanupApiStateAndStore,
    };
};

export default useStoreCleanup;
