//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes from '@components/PropTypes';
import Icon      from '@stateless/atomic/Icon';

import styles from './styles.module.scss';

const propTypes = {
    iconType: PropTypes.iconType,
    text:     PropTypes.children,
};

const UppercaseLabel = ({
    text     = '',
    iconType = null,
}) => {
    function renderIcon() {
        if (iconType) {
            return <Icon iconType={iconType} />;
        }

        return null;
    }

    return (
        <div className={styles.informationLabel}>
            {text}
            {renderIcon()}
        </div>
    );
};

UppercaseLabel.propTypes = propTypes;

export default UppercaseLabel;
