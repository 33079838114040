//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import { useMemo } from 'react';

import _        from 'lodash';
import Skeleton from 'react-loading-skeleton';

import PropTypes       from '@components/PropTypes';
import ContextType     from '@constants/ContextType';
import ContextHelper   from '@helper/ContextHelper';
import CircleIcon      from '@stateless/atomic/CircleIcon';
import CircleIconTheme from '@stateless/atomic/CircleIcon/CircleIconTheme';
import IconColor       from '@stateless/atomic/Icon/IconColor';
import IconType        from '@stateless/atomic/Icon/IconType';
import IconButton      from '@stateless/atomic/IconButton';
import ShortNameLabel  from '@stateless/atomic/ShortNameLabel';

import styles from './styles.module.scss';

const propTypes = {
    context:           PropTypes.object,
    isFavorite:        PropTypes.bool,
    isLoading:         PropTypes.bool,
    onCloseClicked:    PropTypes.func,
    onFavoriteClicked: PropTypes.func,
    type:              PropTypes.string,
};

const ContextInformationHeader = ({
    context           = null,
    type              = null,
    onCloseClicked    = _.noop,
    onFavoriteClicked = _.noop,
    isFavorite        = false,
    isLoading         = false,
}) => {
    const contextName        = useMemo(() => {
        if (!context) {
            return null;
        }

        switch (type) {
            case ContextType.route:
                return _.get(context, 'overview.route.longName');
            case ContextType.stop:
                return _.get(context, 'overview.name');
            default:
                return null;
        }
    }, [context, type]);
    const getStopIconType    = () => {
        const types       = context?.overview?.types;
        const defaultType = context?.overview?.type;
        const stopType    = ContextHelper.getStopTypeByTypes(defaultType, types);

        return ContextHelper.getIconTypeByStopType(stopType);
    };
    const iconType           = useMemo(() => {
        switch (type) {
            case ContextType.route:
                return IconType.line;
            case ContextType.stop:
                return getStopIconType();
            default:
                return null;
        }
    }, [context]);
    const renderContextTitle = () => {
        if (type === ContextType.route) {
            const longName  = _.get(context, 'overview.route.longName');
            const shortName = _.get(context, 'overview.route.shortName');

            return (
                <>
                    <ShortNameLabel text={shortName} />
                    <div className={styles.routeLongName}>
                        {longName}
                    </div>
                </>
            );
        }

        return contextName;
    };

    // TODO: https://lulububu.atlassian.net/browse/BODOBIG-1027
    // const favoriteIconColor = useMemo(() => {
    //    return isFavorite
    //        ? IconColor.yellow
    //        : IconColor.gray;
    // }, [isFavorite]);

    const headerContent = useMemo(() => {
        if (isLoading) {
            return (
                <>
                    <Skeleton
                        height={30}
                        width={30}
                        circle={true}
                    />

                    <Skeleton
                        height={20}
                        width={300}
                    />
                    <div className={styles.contextHeaderActions}>
                        <Skeleton
                            height={20}
                            width={80}
                        />
                    </div>
                </>
            );
        }

        return (
            <>
                <CircleIcon
                    circleIconTheme={CircleIconTheme.yellow}
                    iconType={iconType}
                />
                <div className={styles.contextName}>
                    {renderContextTitle()}
                </div>
                <div className={styles.contextHeaderActions}>
                    {/* TODO: https://lulububu.atlassian.net/browse/BODOBIG-1027 */}
                    {/* <IconButton */}
                    {/*    iconType={IconType.star} */}
                    {/*    iconColor={favoriteIconColor} */}
                    {/*    onIconButtonClicked={onFavoriteClicked} */}
                    {/* /> */}
                    <IconButton
                        iconType={IconType.cross}
                        iconColor={IconColor.gray}
                        onIconButtonClicked={onCloseClicked}
                    />
                </div>
            </>
        );
    }, [isLoading, contextName]);

    return (
        <div className={styles.contextInformationHeaderComponent}>
            {headerContent}
        </div>
    );
};

ContextInformationHeader.propTypes = propTypes;

export default ContextInformationHeader;
