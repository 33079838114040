//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

class DateTime {
    static hoursToSeconds(hours) {
        return hours * 60 * 60;
    }

    static daysToSeconds(days) {
        return DateTime.hoursToSeconds(days * 24);
    }

    static secondsToMinutes(seconds) {
        const minutes = seconds / 60;

        return Math.floor(minutes);
    }
}

export default DateTime;
