//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React             from 'react';
import { createContext } from 'react';
import { useState }      from 'react';
import { useContext }    from 'react';

import _ from 'lodash';

import PropTypes from '@components/PropTypes';

const MapContext = createContext({
    mapInstance:    null,
    setMapInstance: _.noop,
});

const propTypes = {
    children: PropTypes.children,
};

const MapProvider = ({ children }) => {
    const [mapInstance, setMapInstance] = useState(null);

    return (
        <MapContext.Provider
            value={{
                mapInstance,
                setMapInstance,
            }}
        >
            {children}
        </MapContext.Provider>
    );
};

MapProvider.propTypes = propTypes;

export default MapProvider;

export const useMapContext = () => useContext(MapContext);
