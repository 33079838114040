//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes      from '@components/PropTypes';
import LoadingSpinner from '@stateless/atomic/LoadingSpinner';

import styles from './styles.module.scss';

const propTypes = {
    isLoading: PropTypes.bool,
};

const MapLoadingLayer = ({
    isLoading = false,
}) => {
    return (
        <div className={styles.mapLoadingContainer}>
            <LoadingSpinner isLoading={isLoading} />
        </div>
    );
};

MapLoadingLayer.propTypes = propTypes;

export default MapLoadingLayer;
