//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    isLoading: PropTypes.bool,
};

const LoadingSpinner = ({
    isLoading = false,
}) => {
    return (
        <div
            className={classNames(styles.loadingSpinner, {
                [styles.visible]: isLoading,
            })}
        />
    );
};

LoadingSpinner.propTypes = propTypes;

export default LoadingSpinner;
