//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch }    from 'react-redux';

import useRouteSearchFormControl from '@hooks/useRouteSearchFormControl';
import { useSearchActions }      from '@slices/search';
import SimpleButton              from '@stateless/atomic/SimpleButton';
import StopSearchInput           from '@stateless/composed/StopSearchInput';

const StopSearchForm = () => {
    const { t }         = useTranslation(null, {
        keyPrefix: 'components.search',
    });
    const {
        executeSearchFromStop,
        executeSearchToStop,
        setFromStopSearchQuery,
        setToStopSearchQuery,
        displayFromStopResults,
        displayToStopResults,
        fromStopSearchQuery,
        toStopSearchQuery,
        hideFromStopResults,
        hideToStopResults,
        isFetchingFromStops,
        isFetchingToStops,
        fromStopResults,
        isRouteSearchFormComplete,
        toStopResults,
    }             = useRouteSearchFormControl();
    const dispatch      = useDispatch();
    const SearchActions = useSearchActions(dispatch);

    function onSubmitButtonClicked() {
        if (isRouteSearchFormComplete) {
            SearchActions.setShowRouteSearchResults({
                showRouteSearchResults: true,
            });
        }
    }

    function onStopSearchFormBlurred(event) {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            hideToStopResults();
            hideFromStopResults();
        }
    }

    function onFromStopResultClicked(stop) {
        return () => {
            SearchActions.setRouteFromStop({
                id:   stop.id,
                name: stop.nameWithLocation,
            });
            setFromStopSearchQuery(stop.nameWithLocation);
            hideFromStopResults();
        };
    }

    function onToStopResultClicked(stop) {
        return () => {
            SearchActions.setRouteToStop({
                id:   stop.id,
                name: stop.nameWithLocation,
            });
            setToStopSearchQuery(stop.nameWithLocation);
            hideToStopResults();
        };
    }

    function onFromStopSearchQueryChanged(event) {
        setFromStopSearchQuery(event.target.value);
        SearchActions.setRouteFromStop({
            id:   null,
            name: null,
        });
        hideFromStopResults();
    }

    function onToStopSearchQueryChanged(event) {
        setToStopSearchQuery(event.target.value);
        SearchActions.setRouteToStop({
            id:   null,
            name: null,
        });
        hideToStopResults();
    }

    return (
        <div
            onBlur={onStopSearchFormBlurred}
        >
            <StopSearchInput
                value={fromStopSearchQuery}
                onInputChanges={onFromStopSearchQueryChanged}
                labelText={t('searchRouteFromLabel')}
                searchResults={fromStopResults}
                showResults={displayFromStopResults}
                isLoading={isFetchingFromStops}
                onSubmit={executeSearchFromStop}
                onResultClicked={onFromStopResultClicked}
            />
            <StopSearchInput
                value={toStopSearchQuery}
                onInputChanges={onToStopSearchQueryChanged}
                labelText={t('searchRouteToLabel')}
                searchResults={toStopResults}
                showResults={displayToStopResults}
                isLoading={isFetchingToStops}
                onSubmit={executeSearchToStop}
                onResultClicked={onToStopResultClicked}
            />
            <SimpleButton
                disabled={!isRouteSearchFormComplete}
                text={t('searchRouteButtonText')}
                onClick={onSubmitButtonClicked}
            />
        </div>
    );
};

export default StopSearchForm;
