//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    context: PropTypes.object,
};

const ContextReachability = ({
    context = null,
}) => {
    return (
        <div className={styles.contextReachabilityComponent}>
            Erreichbarkeit
        </div>
    );
};

ContextReachability.propTypes = propTypes;

export default ContextReachability;
