//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import { useMemo } from 'react';

import _ from 'lodash';

import PropTypes  from '@components/PropTypes';
import IconColor  from '@stateless/atomic/Icon/IconColor';
import IconType   from '@stateless/atomic/Icon/IconType';
import IconButton from '@stateless/atomic/IconButton';

import styles from './styles.module.scss';

const propTypes = {
    expanded:               PropTypes.bool,
    onSidebarToggleClicked: PropTypes.func,
};

const SidebarToggleButton = ({
    onSidebarToggleClicked = _.noop,
    expanded               = false,
}) => {
    const toggleIconType = useMemo(() => {
        return expanded
            ? IconType.cross
            : IconType.burger;
    }, [expanded]);

    return (
        <IconButton
            iconColor={IconColor.darkAbyss}
            className={styles.sidebarToggleButton}
            iconType={toggleIconType}
            onIconButtonClicked={onSidebarToggleClicked}
        />
    );
};

SidebarToggleButton.propTypes = propTypes;

export default SidebarToggleButton;
