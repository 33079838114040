//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';

import DefaultTypes           from '@components/DefaultTypes';
import PropTypes              from '@components/PropTypes';
import Icon                   from '@stateless/atomic/Icon';
import IconType               from '@stateless/atomic/Icon/IconType';
import TextInputSize          from '@stateless/atomic/InputWrapper/TextInputSize';
import TextInputTextAlignment from '@stateless/atomic/InputWrapper/TextInputTextAlignment';
import SimpleButton           from '@stateless/atomic/SimpleButton';
import SimpleButtonTheme      from '@stateless/atomic/SimpleButton/SimpleButtonTheme';
import TextInput              from '@stateless/atomic/TextInput';
import TextInputType          from '@stateless/atomic/TextInput/TextInputType';

import styles from './styles.module.scss';

const propTypes = {
    authenticate:    PropTypes.func,
    password:        PropTypes.string,
    passwordChanged: PropTypes.func,
};

const StagingLoginOverlay = ({
    authenticate    = DefaultTypes.func,
    password        = null,
    passwordChanged = DefaultTypes.func,
}) => {
    function onAuthenticateClicked() {
        authenticate();
    }

    function onPasswordChanged(event) {
        passwordChanged({
            password: event.target.value,
        });
    }

    return (
        <div className={styles.stagingLoginOverlayContainer}>
            <div className={styles.stagingLoginOverlay}>
                <Icon iconType={IconType.logo} />
                <span>
                    {I18n.t('stagingLogin')}
                </span>
                <div className={styles.formWrapper}>
                    <TextInput
                        onChange={onPasswordChanged}
                        placeholderText={I18n.t('stagingPasswordPlaceholder')}
                        size={TextInputSize.default}
                        textAlignment={TextInputTextAlignment.left}
                        type={TextInputType.password}
                        value={password}
                    />
                    <SimpleButton
                        onClick={onAuthenticateClicked}
                        theme={SimpleButtonTheme.default}
                        text={I18n.t('login')}
                    />
                </div>
            </div>
        </div>
    );
};

StagingLoginOverlay.propTypes = propTypes;

export default StagingLoginOverlay;
