//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _               from 'lodash';
import hash            from 'object-hash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import ContextType           from '@constants/ContextType';
import StopType              from '@constants/StopType';
import ContextHelper         from '@helper/ContextHelper';
import { useHistoryActions } from '@slices/history';
import selectSelectedContext from '@store/selectors/map';

const getContextDataName = (data) => {
    const type = _.get(data, 'request.type', null);
    let name   = '';

    if (type === ContextType.route) {
        name = _.get(data, 'context.overview.route.shortName', '');
    } else if (type === ContextType.stop) {
        name = _.get(data, 'context.overview.name', '');
    }

    return name;
};

const createHistoryHash = (id, type, dateRange) => {
    return hash({
        id,
        type,
        dateRange,
    });
};

const useContextHistory = () => {
    const dispatch          = useDispatch();
    const selectedContext   = useSelector(selectSelectedContext);
    const contextHistory    = useSelector((state) => state.history.contextHistory);
    const selectedDateRange = useSelector((state) => state.map.selectedDateRange);
    const historyActions    = useHistoryActions(dispatch);

    function addToHistory(data) {
        const historyHash           = createHistoryHash(
            selectedContext.id,
            selectedContext.type,
            selectedDateRange,
        );
        const existingHistoryRecord = _.find(contextHistory, {
            hash: historyHash,
        });

        if (!existingHistoryRecord) {
            const types    = _.get(data, 'context.overview.types', null);
            const stopType = ContextHelper.getStopTypeByTypes(StopType.busStop, types);

            historyActions.addContextHistory({
                context: {
                    hash:       historyHash,
                    name:       getContextDataName(data),
                    id:         selectedContext.id,
                    type:       selectedContext.type,
                    dateRange:  selectedDateRange,
                    activeTab:  selectedContext.activeTab,
                    tabOptions: selectedContext.tabOptions,
                    stopType,
                },
            });
            historyActions.setActiveContextHistory({
                hash: historyHash,
            });
        }
    }

    function updateHistoryItem(contextData) {
        const historyHash           = createHistoryHash(
            contextData.id,
            contextData.type,
            contextData.dateRange,
        );
        const existingHistoryRecord = _.find(contextHistory, {
            hash: historyHash,
        });

        if (existingHistoryRecord) {
            historyActions.updateContextHistory({
                context: {
                    ...existingHistoryRecord,
                    ...contextData,
                    isActive: true,
                },
            });
        }

        historyActions.setActiveContextHistory({
            hash: historyHash,
        });
    }

    function unselectHistory() {
        historyActions.deactivateContextHistory();
    }

    return {
        addToHistory,
        updateHistoryItem,
        unselectHistory,
    };
};

export default useContextHistory;
