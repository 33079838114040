//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _               from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import ContextInformationPanel from '@connected/ContextInformationPanel';
import { DateRangeDefault }    from '@helper/DateRange';
import { useMapActions }       from '@slices/map';
import DateRangePicker         from '@stateless/composed/DateRangePicker';

import styles from './styles.module.scss';

const propTypes = {};

const ControlLayer = () => {
    const showDateRangePicker = useSelector((state) => state.map.showDateRangePicker);
    const selectedDateRange   = useSelector((state) => state.map.selectedDateRange);
    const dispatch            = useDispatch();
    const mapActions          = useMapActions(dispatch);

    function onCloseDateRangePickerClicked() {
        mapActions.setShowDateRangePicker({
            show: false,
        });
    }

    function onDateRangeSaveClicked(item) {
        mapActions.setSelectedDateRange(item);
        onCloseDateRangePickerClicked();
    }

    function onDeleteDateRangeSelectionClicked() {
        mapActions.setSelectedDateRange(DateRangeDefault);
        onCloseDateRangePickerClicked();
    }

    function renderDateRangePicker() {
        return (
            <div
                key={'dateRangePicker'}
                className={styles.dateRangePickerContainer}
            >
                <DateRangePicker
                    value={selectedDateRange ?? DateRangeDefault}
                    onSaveClicked={onDateRangeSaveClicked}
                    onCloseClicked={onCloseDateRangePickerClicked}
                    onDeleteClicked={onDeleteDateRangeSelectionClicked}
                />
            </div>
        );
    }

    const mapOverlayItems = [
        {
            component: <ContextInformationPanel key={'contextInformationPanel'} />,
            active:    true,
        },
        {
            component: renderDateRangePicker(),
            active:    showDateRangePicker,
        },
    ];

    function renderOverlayItems() {
        return _.map(mapOverlayItems, (item) => {
            const { component, active } = item;

            if (!active) {
                return null;
            }

            return component;
        });
    }

    return (
        <div className={styles.controlLayerComponent}>
            {renderOverlayItems()}
        </div>
    );
};

ControlLayer.propTypes = propTypes;

export default ControlLayer;
