//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _                  from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch }    from 'react-redux';

import QuickViewType           from '@constants/QuickViewType';
import { useQuickViewActions } from '@slices/quickView';
import IconType                from '@stateless/atomic/Icon/IconType';
import SidebarLinkItem         from '@stateless/composed/SidebarLinkItem';
import SidebarSeparator        from '@stateless/composed/SidebarSeparator';

import styles from './styles.module.scss';

const SidebarQuickActions = () => {
    const { t }            = useTranslation(null, {
        keyPrefix: 'components.action',
    });
    const dispatch         = useDispatch();
    const QuickViewActions = useQuickViewActions(dispatch);
    const quickActionItems = [
        {
            type:     QuickViewType.mostTransits,
            iconType: IconType.passengerVolume,
        },
        {
            type:     QuickViewType.leastTransits,
            iconType: IconType.user,
        },
        {
            type:     QuickViewType.reliableTransits,
            iconType: IconType.punctuality,
        },
        {
            type:     QuickViewType.unreliableTransits,
            iconType: IconType.clock,
        },
    ];

    function onItemClicked(quickViewType) {
        return () => {
            QuickViewActions.setActiveQuickViewType({
                quickViewType,
            });
            QuickViewActions.setShowQuickViewSidebarDetails({
                showQuickViewSidebarDetails: true,
            });
        };
    }

    function renderLinkItems() {
        return _.map(quickActionItems, (item, index) => {
            const { iconType, type } = item;

            return (
                <SidebarLinkItem
                    key={type}
                    title={t(type)}
                    iconType={iconType}
                    onItemClicked={onItemClicked(item.type)}
                />
            );
        });
    }

    return (
        <>
            <SidebarSeparator
                text={t('quickView')}
            />
            <div className={styles.sidebarQuickActionsComponents}>
                {renderLinkItems()}
            </div>
        </>
    );
};

export default SidebarQuickActions;
