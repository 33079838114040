//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useState } from 'react';
import { useMemo }  from 'react';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { useLazySearchRoutesQuery } from '@store/api/routes';
import { useLazySearchStopsQuery }  from '@store/api/stops';
import { stopsApi }                 from '@store/api/stops';

const useStopSearchFormControl = () => {
    const dispatch                                                                                  = useDispatch();
    const routeFromStop                                                                             = useSelector((state) => state.search.routeFromStop);
    const routeToStop                                                                               = useSelector((state) => state.search.routeToStop);
    const [fromStopSearchQuery, setFromStopSearchQuery]                                             = useState(routeFromStop?.name);
    const [toStopSearchQuery, setToStopSearchQuery]                                                 = useState(routeToStop?.name);
    const [displayFromStopResults, setDisplayFromStopResults]                                       = useState(false);
    const [displayToStopResults, setDisplayToStopResults]                                           = useState(false);
    const [fromStopTrigger, { data: fromStopResults, isFetching: isFetchingFromStops }]             = useLazySearchStopsQuery();
    const [toStopTrigger, { data: toStopResults, isFetching: isFetchingToStops }]                   = useLazySearchStopsQuery();
    const [routesTrigger, { data: routesResult, isFetching: isFetchingRoutes, error: routesError }] = useLazySearchRoutesQuery();
    const isRouteSearchFormComplete                                                                 = useMemo(
        () => routeFromStop?.id && routeToStop?.id,
        [routeFromStop, routeToStop],
    );

    function hideFromStopResults() {
        setDisplayFromStopResults(false);
        dispatch(stopsApi.util.resetApiState());
    }

    function showFromStopResults() {
        setDisplayFromStopResults(true);
    }

    function hideToStopResults() {
        setDisplayToStopResults(false);
        dispatch(stopsApi.util.resetApiState());
    }

    function showToStopResults() {
        setDisplayToStopResults(true);
    }

    function executeSearchFromStop() {
        const options = {
            query: fromStopSearchQuery,
        };

        if (routeToStop?.id) {
            options.queryToId = routeToStop.id;
        }

        fromStopTrigger(options);
        showFromStopResults();
    }

    function executeSearchToStop() {
        const options = {
            query: toStopSearchQuery,
        };

        if (routeFromStop?.id) {
            options.queryFromId = routeFromStop.id;
        }

        toStopTrigger(options);
        showToStopResults();
    }

    function executeRoutesSearch() {
        if (
            !routeFromStop?.id ||
            !routeToStop?.id
        ) {
            return;
        }

        routesTrigger({
            fromId: routeFromStop.id,
            toId:   routeToStop.id,
        });
    }

    return {
        fromStopSearchQuery,
        toStopSearchQuery,
        displayFromStopResults,
        displayToStopResults,
        showFromStopResults,
        showToStopResults,
        hideFromStopResults,
        hideToStopResults,
        executeSearchFromStop,
        executeSearchToStop,
        isFetchingFromStops,
        isFetchingToStops,
        fromStopResults,
        toStopResults,
        setFromStopSearchQuery,
        setToStopSearchQuery,
        isRouteSearchFormComplete,
        executeRoutesSearch,
        routesResult,
        isFetchingRoutes,
        routesError,
    };
};

export default useStopSearchFormControl;
