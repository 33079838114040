//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _                  from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector }    from 'react-redux';

import ContextType      from '@constants/ContextType';
import MapFilter        from '@constants/MapFilter';
import Zoom             from '@constants/Zoom';
import FilterHelper     from '@helper/FilterHelper';
import useContextLoader from '@hooks/useContextLoader';
import Icon             from '@stateless/atomic/Icon';
import IconColor        from '@stateless/atomic/Icon/IconColor';
import IconType         from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const MapHintLayer = () => {
    const { t }               = useTranslation(null, {
        keyPrefix: 'hints',
    });
    const filter              = useSelector((state) => state.map.filter) ?? [];
    const zoom                = useSelector((state) => state.map.zoom);
    const { selectedContext } = useContextLoader();
    const shouldShowZoomHint  = (
        zoom < Zoom.ShowRoutesHintZoom &&
        FilterHelper.filterIsSet(filter, MapFilter.lines) &&
        selectedContext?.type !== ContextType.route
    );
    const hintItems           = [
        {
            icon:      IconType.warning,
            text:      t('noVisibleElementsSelected'),
            condition: _.isEmpty(filter),
        },
        {
            icon:      IconType.line,
            text:      t('zoomToBigForRoutes'),
            condition: shouldShowZoomHint,
        },
    ];

    function renderHintItems() {
        return _.map(hintItems, (hintItem, index) => {
            if (!hintItem.condition) {
                return null;
            }

            return (
                <div
                    key={index}
                    className={styles.hintItem}
                >
                    <Icon
                        iconType={hintItem.icon}
                        iconColor={IconColor.gray}
                    />
                    <span className={styles.hintText}>
                        {hintItem.text}
                    </span>
                </div>
            );
        });
    }

    return (
        <div className={styles.mapHintLayer}>
            {renderHintItems()}
        </div>
    );
};

export default MapHintLayer;
