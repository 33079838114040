//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import update                 from 'immutability-helper';
import { bindActionCreators } from 'redux';

import SearchMode from '@constants/SearchMode';

const initialState = Object.freeze({
    showQuickViewSidebarDetails: false,
    activeQuickViewType:         null,
    searchMode:                  SearchMode.stops,
});

const quickViewSlice = createSlice({
    name:     'quickView',
    initialState,
    reducers: {
        setShowQuickViewSidebarDetails: (state, action) => {
            const { showQuickViewSidebarDetails } = action.payload;

            return update(state, {
                showQuickViewSidebarDetails: {
                    $set: showQuickViewSidebarDetails,
                },
            });
        },
        setActiveQuickViewType:         (state, action) => {
            const { quickViewType } = action.payload;

            return update(state, {
                activeQuickViewType: {
                    $set: quickViewType,
                },
            });
        },
        setSearchMode:                  (state, action) => {
            const { searchMode } = action.payload;

            return update(state, {
                searchMode: {
                    $set: searchMode,
                },
            });
        },
    },
});

export const QuickViewActions = quickViewSlice.actions;

export const QuickViewReducer = quickViewSlice.reducer;

export const useQuickViewActions = (dispatch) => bindActionCreators(QuickViewActions, dispatch);

export default quickViewSlice;
