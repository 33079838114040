//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import { useMemo } from 'react';

import _ from 'lodash';

import PropTypes                      from '@components/PropTypes';
import Icon                           from '@stateless/atomic/Icon';
import IconColor                      from '@stateless/atomic/Icon/IconColor';
import IconType                       from '@stateless/atomic/Icon/IconType';
import SidebarListItem                from '@stateless/composed/SidebarListItem';
import SidebarListItemBackgroundColor from '@stateless/composed/SidebarListItem/SidebarListItemBackgroundColor';

import styles from './styles.module.scss';

const propTypes = {
    active:        PropTypes.bool,
    iconType:      PropTypes.iconType,
    onItemClicked: PropTypes.func,
    title:         PropTypes.string,
};

const SidebarLinkItem = ({
    active        = false,
    iconType      = null,
    title         = '',
    onItemClicked = _.noop,
}) => {
    const backgroundColor = useMemo(() => {
        return active
            ? SidebarListItemBackgroundColor.lightGray
            : SidebarListItemBackgroundColor.default;
    }, [active]);

    function renderLinkIconView() {
        return (
            <div className={styles.iconWrapper}>
                <Icon
                    iconType={IconType.chevronRight}
                    iconColor={IconColor.darkAbyss}
                />
            </div>
        );
    }

    return (
        <SidebarListItem
            backgroundColor={backgroundColor}
            iconType={iconType}
            title={title}
            iconColor={IconColor.darkAbyss}
            customView={renderLinkIconView()}
            onItemClicked={onItemClicked}
        />
    );
};

SidebarLinkItem.propTypes = propTypes;

export default SidebarLinkItem;
