//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useEffect } from 'react';
import { useRef }    from 'react';

import { getDistance } from 'geolib';
import { useSelector } from 'react-redux';

import { useMapContext } from '@components/MapProvider';
import ContextType       from '@constants/ContextType';
import Coordinates       from '@constants/Coordinates';
import Zoom              from '@constants/Zoom';
import useContextApi     from '@hooks/useContextApi';
import useContextLoader  from '@hooks/useContextLoader';

const MAX_DISRTANCE_TO_RAVENSBURG_IN_METERS = 250000;

const useMapNavigation = () => {
    const { mapInstance }             = useMapContext();
    const { data, error, isFetching } = useContextApi();
    const { selectedContext }         = useContextLoader();
    const previousSelectedContextId   = useRef(selectedContext?.id);
    const currentMapZoom              = useSelector((state) => state.map.zoom);
    const navigateTo                  = ({
        latitude,
        longitude,
        zoom,
    }) => {
        if (mapInstance) {
            mapInstance.flyTo([latitude, longitude], zoom);
        }
    };
    const navigateToBounds            = ({ top, right, bottom, left }) => {
        const invalidBounds = (
            !top ||
            !right ||
            !bottom ||
            !left
        );

        if (
            mapInstance &&
            !invalidBounds
        ) {
            const bounds = [
                [right, bottom],
                [left, top],
            ];

            mapInstance.flyToBounds(bounds);
        }
    };
    const navigateToSelectedContext   = () => {
        if (
            error ||
            !data
        ) {
            return;
        }

        const requestContextType = data.request.type;

        if (requestContextType === ContextType.stop) {
            const coordinates                  = data.context.overview.coordinates;
            const distanceToRavensburgInMeters = getDistance(
                Coordinates.Ravensburg,
                coordinates,
            );
            const coordinatesAreFarAway        = distanceToRavensburgInMeters > MAX_DISRTANCE_TO_RAVENSBURG_IN_METERS;

            if (
                !coordinates ||
                !coordinates.latitude ||
                !coordinates.longitude ||
                coordinatesAreFarAway
            ) {
                return;
            }

            const targetMapZoom = (
                currentMapZoom < Zoom.MinStopZoom ?
                    Zoom.MinStopZoom :
                    currentMapZoom
            );

            navigateTo({
                ...coordinates,
                zoom: targetMapZoom,
            });
        } else if (requestContextType === ContextType.route) {
            const bounds = data.context.overview.route.boundingBox;

            navigateToBounds(bounds);
        }
    };

    useEffect(() => {
        if (isFetching) {
            return;
        }

        if (
            data &&
            previousSelectedContextId.current !== selectedContext?.id
        ) {
            navigateToSelectedContext();
        }

        previousSelectedContextId.current = selectedContext?.id;
    }, [data, isFetching]);

    return {
        navigateTo,
        navigateToSelectedContext,
    };
};

export default useMapNavigation;
