//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _                  from 'lodash';
import { useTranslation } from 'react-i18next';

import PropTypes       from '@components/PropTypes';
import CircleIcon      from '@stateless/atomic/CircleIcon';
import CircleIconTheme from '@stateless/atomic/CircleIcon/CircleIconTheme';
import IconColor       from '@stateless/atomic/Icon/IconColor';
import IconType        from '@stateless/atomic/Icon/IconType';
import IconButton      from '@stateless/atomic/IconButton';
import SidebarListItem from '@stateless/composed/SidebarListItem';

import styles from './styles.module.scss';

const propTypes = {
    iconType:          PropTypes.iconType,
    isFavorite:        PropTypes.bool,
    onFavoriteClicked: PropTypes.func,
    onItemClicked:     PropTypes.func,
    personCount:       PropTypes.number,
    title:             PropTypes.string,
};

const SidebarStopDetailItem = ({
    iconType          = null,
    isFavorite        = false,
    onFavoriteClicked = _.noop,
    onItemClicked     = _.noop,
    personCount       = 0,
    title             = '',
}) => {
    const { t } = useTranslation(null, {
        keyPrefix: 'components.search',
    });

    // TODO: https://lulububu.atlassian.net/browse/BODOBIG-1027
    // function onFavoriteButtonClicked(event) {
    //    event.stopPropagation();
    //
    //    onFavoriteClicked();
    // }

    function renderIconView() {
        if (!iconType) {
            return null;
        }

        return (
            <CircleIcon
                iconType={iconType}
                circleIconTheme={CircleIconTheme.yellow}
            />
        );
    }

    function renderTitleView() {
        return (
            <div className={styles.titleView}>
                <span>
                    {title}
                </span>
                <span>
                    {personCount} {t('people')}
                </span>
            </div>
        );
    }

    function renderCustomView() {
        // TODO: https://lulububu.atlassian.net/browse/BODOBIG-1027
        // const iconColor = isFavorite
        //    ? IconColor.yellow
        //    : IconColor.gray;

        return (
            <div className={styles.customView}>
                {/* TODO: https://lulububu.atlassian.net/browse/BODOBIG-1027 */}
                {/* <IconButton */}
                {/*    iconType={IconType.star} */}
                {/*    iconColor={iconColor} */}
                {/*    onIconButtonClicked={onFavoriteButtonClicked} */}
                {/* /> */}
                <IconButton
                    iconType={IconType.chevronRight}
                    iconColor={IconColor.gray}
                />
            </div>
        );
    }

    return (
        <SidebarListItem
            iconView={renderIconView()}
            titleView={renderTitleView()}
            customView={renderCustomView()}
            onItemClicked={onItemClicked}
        />
    );
};

SidebarStopDetailItem.propTypes = propTypes;

export default SidebarStopDetailItem;
