//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes       from '@components/PropTypes';
import CircleIcon      from '@stateless/atomic/CircleIcon';
import CircleIconTheme from '@stateless/atomic/CircleIcon/CircleIconTheme';

import styles from './styles.module.scss';

const propTypes = {
    iconType: PropTypes.iconType,
    text:     PropTypes.string,
};

const IconTextRow = ({
    text     = '',
    iconType = null,
}) => {
    return (
        <div className={styles.iconTextRow}>
            <CircleIcon
                circleIconTheme={CircleIconTheme.yellow}
                iconType={iconType}
            />
            <span>
                {text}
            </span>
        </div>
    );
};

IconTextRow.propTypes = propTypes;

export default IconTextRow;
