//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames         from 'classnames';
import { useTranslation } from 'react-i18next';
import Skeleton           from 'react-loading-skeleton';

import PropTypes               from '@components/PropTypes';
import ContextInformationTab   from '@connected/ContextInformationPanel/ContextInformationTab';
import ContextHelper           from '@helper/ContextHelper';
import withContextPanelPadding from '@hoc/ContextPanelPadding';
import ContextConnections      from '@stateless/composed/ContextTabs/ContextConnections';
import ContextOverview         from '@stateless/composed/ContextTabs/ContextOverview';
import ContextPunctuality      from '@stateless/composed/ContextTabs/ContextPunctuality';
import ContextReachability     from '@stateless/composed/ContextTabs/ContextReachability';
import ContextVolume           from '@stateless/composed/ContextTabs/ContextVolume';
import ContextWeather          from '@stateless/composed/ContextTabs/ContextWeather';

import styles from './styles.module.scss';

const propTypes = {
    activeTab: PropTypes.oneOfObjectKeys(ContextInformationTab),
    context:   PropTypes.object,
    isLoading: PropTypes.bool,
};

const noDataConditions = {
    [ContextInformationTab.overview]:     (context) => !context?.overview?.routes?.length,
    [ContextInformationTab.connections]:  (context) => !context?.transits?.length,
    [ContextInformationTab.punctuality]:  ContextHelper.shouldDisplayPunctualityNoData,
    [ContextInformationTab.volume]:       ContextHelper.shouldDisplayPassengerVolumeNoData,
    [ContextInformationTab.reachability]: (context) => !context?.reachability?.length,
};

const ContextInformationContent = ({
    activeTab = ContextInformationTab.overview,
    isLoading = false,
    context   = null,
}) => {
    const { t } = useTranslation(null, {
        keyPrefix: 'components.context',
    });

    function renderLoadingContent() {
        return withContextPanelPadding(<div className={styles.loadingWrapper}>
            <Skeleton count={2} />
            <Skeleton
                count={1}
                width={500}
            />
        </div>);
    }

    // @formatter:off
    const contentItems = {
        [ContextInformationTab.overview]:     <ContextOverview context={context} />,
        [ContextInformationTab.weather]:      <ContextWeather context={context} />,
        [ContextInformationTab.connections]:  <ContextConnections context={context} />,
        [ContextInformationTab.punctuality]:  <ContextPunctuality context={context} />,
        [ContextInformationTab.volume]:       <ContextVolume context={context} />,
        [ContextInformationTab.reachability]: <ContextReachability context={context} />,
    };
    // @formatter:on

    function renderActiveContentItem() {
        if (isLoading) {
            return renderLoadingContent();
        }

        const noDataConditionFunction = noDataConditions[activeTab];

        if (
            noDataConditionFunction
            && noDataConditionFunction(context)
        ) {
            return withContextPanelPadding(<div className={styles.noDataLabel}>
                {t('noDataLabel')}
            </div>);
        }

        return contentItems[activeTab];
    }

    return (
        <div
            className={classNames(
                styles.contextInformationContentComponent,
                {
                    [styles.hidden]: !activeTab,
                },
            )}
        >
            {renderActiveContentItem()}
        </div>
    );
};

ContextInformationContent.propTypes = propTypes;

export default ContextInformationContent;
