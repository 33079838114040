//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import I18n                   from 'i18next';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    lastLogin: null,
    password:  '',
    token:     null,
    username:  '',
    name:      '',
});

const userSlice = createSlice({
    name:     'user',
    initialState,
    reducers: {
        login:                LoadingLevelHelper.increaseLoading(
            () => {
            },
            I18n.t('loadingLogin'),
        ),
        loginWithCredentials: _.noop,
        loginFailed:          LoadingLevelHelper.decreaseLoading(() => {
        }),
        loginSucceeded:       LoadingLevelHelper.decreaseLoading((state, action) => {
            const payload = _.get(action, 'payload', {});

            return update(state, {
                lastLogin: {
                    $set: payload.lastLogin.date,
                },
                password:  {
                    $set: '',
                },
                token:     {
                    $set: payload.token,
                },
                username:  {
                    $set: payload.username,
                },
                name:      {
                    $set: payload.name,
                },
            });
        }),
        logout:               (state) => {
            return update(state, {
                $set: initialState,
            });
        },
        setPassword:          (state, action) => {
            const payload = _.get(action, 'payload', {});

            return update(state, {
                password: {
                    $set: payload.password,
                },
            });
        },
        setUsername:          (state, action) => {
            const payload = _.get(action, 'payload', {});

            return update(state, {
                username: {
                    $set: payload.username,
                },
            });
        },
        tryRestoreToken:      (state, action) => {
        },
    },
});

export const UserActions = userSlice.actions;

export const UserReducer = userSlice.reducer;
export const useUser     = (dispatch) => bindActionCreators(UserActions, dispatch);

export default userSlice;
