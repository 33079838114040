//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import SidebarRouteSearchForm from '@connected/SidebarRouteSearchForm';
import SidebarStopSearchForm  from '@connected/SidebarStopSearchForm';
import SearchMode             from '@constants/SearchMode';
import withSidebarListPadding from '@hoc/SidebarListPadding';
import LabeledSwitch          from '@stateless/atomic/LabeledSwitch';
import SidebarSeparator       from '@stateless/composed/SidebarSeparator';

import styles from './styles.module.scss';

const SidebarSearchForm = () => {
    const { t }                       = useTranslation(null, {
        keyPrefix: 'components.search',
    });
    const [searchMode, setSearchMode] = useState(SearchMode.stops);

    function onOptionClicked(option) {
        setSearchMode(option);
    }

    function renderSearchForm() {
        if (searchMode === SearchMode.stops) {
            return <SidebarStopSearchForm />;
        }

        if (searchMode === SearchMode.routes) {
            return <SidebarRouteSearchForm />;
        }

        return null;
    }

    return (
        <>
            <SidebarSeparator
                text={t('stopsAndRoutes')}
            />
            {withSidebarListPadding(<LabeledSwitch
                leftOption={SearchMode.stops}
                rightOption={SearchMode.routes}
                selectedOption={searchMode}
                onOptionClicked={onOptionClicked}
                width={240}
            />)}
            <div className={styles.searchFormWrapper}>
                {renderSearchForm()}
            </div>
        </>
    );
};

export default SidebarSearchForm;
