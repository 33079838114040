//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { CALL_HISTORY_METHOD } from 'connected-react-router';
import { LOCATION_CHANGE }     from 'connected-react-router';
import _                       from 'lodash';
import { REHYDRATE }           from 'redux-persist';
import { PERSIST }             from 'redux-persist';
import { FLUSH }               from 'redux-persist';
import { PAUSE }               from 'redux-persist';
import { PURGE }               from 'redux-persist';
import { REGISTER }            from 'redux-persist';

import ActionHelper                     from '@helper/ActionHelper';
import Environment                      from '@helper/Environment';
import Token                            from '@helper/Token';
import { LoadingActions }               from '@slices/loading';
import { NavigationActions }            from '@slices/navigation';
import { StagingAuthenticationActions } from '@slices/stagingAuthentication';
import { UserActions }                  from '@slices/user';
import { WindowActions }                from '@slices/window';
import calendarApi                      from '@store/api/calendar';
import connectionsView                  from '@store/api/connectionsView';
import contextApi                       from '@store/api/context';
import polylineApi                      from '@store/api/polyline';
import polylinesApi                     from '@store/api/polylines';
import { regionApi }                    from '@store/api/region';
import routesApi                        from '@store/api/routes';
import stopsApi                         from '@store/api/stops';
import tripsApi                         from '@store/api/trips';

const whitelistedReactActions = [
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    CALL_HISTORY_METHOD,
    LOCATION_CHANGE,
    ...ActionHelper.getMultipleApiActionsToWhitelist([
        calendarApi,
        connectionsView,
        contextApi,
        polylineApi,
        polylinesApi,
        regionApi,
        routesApi,
        stopsApi,
        tripsApi,
    ]),
];

const actionBlockerConfigurations = {
    jwtTokenNotValid: {
        actionsToBlock:               [],
        blockAllActionsExceptAllowed: true,
        allowedActions:               [
            LoadingActions.increaseLevel().type,
            LoadingActions.decreaseLevel().type,
            LoadingActions.resetClickCount().type,
            LoadingActions.resetOverlay().type,
            NavigationActions.redirect().type,
            StagingAuthenticationActions.authenticate().type,
            StagingAuthenticationActions.authenticationPasswordChanged().type,
            StagingAuthenticationActions.authenticateSucceeded().type,
            StagingAuthenticationActions.authenticateFailed().type,
            UserActions.login().type,
            UserActions.loginWithCredentials().type,
            UserActions.loginFailed().type,
            UserActions.loginSucceeded().type,
            UserActions.logout().type,
            UserActions.setPassword().type,
            UserActions.setUsername().type,
            UserActions.tryRestoreToken().type,
            WindowActions.windowBecameHidden().type,
            WindowActions.windowBecameVisible().type,
            WindowActions.windowGotFocus().type,
            WindowActions.windowLostFocus().type,
        ],
        shouldActionsBlockedFunction: (action, state) => {
            const token = _.get(state, ['user', 'token']);

            return (
                !token ||
                !Token.isValidJWTToken(token)
            );
        },
    },
};

function actionBlockerMiddleware() {
    return ({ dispatch, getState }) => (next) => (action) => {
        let skipAction = false;

        for (const configurationKey in actionBlockerConfigurations) {
            const configuration                                                = actionBlockerConfigurations[configurationKey];
            const { allowedActions, actionsToBlock, dataPropertyToBlockValue } = configuration;
            const { dataPropertyInReducer, dataPropertyToBlock }               = configuration;
            const { shouldActionsBlockedFunction }                             = configuration;
            const blockAllActionsExceptAllowed                                 = _.get(configuration, 'blockAllActionsExceptAllowed', false);
            const actionType                                                   = action.type;
            const actionTypeContainedInBlocked                                 = _.includes(actionsToBlock, actionType);
            const actionTypeContainedInAllowed                                 = _.includes(allowedActions, actionType);

            if (
                (
                    actionTypeContainedInBlocked ||
                    blockAllActionsExceptAllowed
                ) &&
                !actionTypeContainedInAllowed &&
                !_.includes(whitelistedReactActions, actionType)
            ) {
                if (shouldActionsBlockedFunction) {
                    const shouldBlockAction = shouldActionsBlockedFunction(action, getState());

                    if (shouldBlockAction) {
                        console.info(`actionBlockerMiddleware: blocked action by ${configurationKey}:`, action);

                        skipAction = true;
                    }

                    break;
                }

                const stateDataPropertyValue = _.get(getState(), [dataPropertyInReducer, dataPropertyToBlock]);

                if (_.eq(stateDataPropertyValue, dataPropertyToBlockValue)) {
                    console.info(`actionBlockerMiddleware: blocked action by ${configurationKey}:`, action);

                    skipAction = true;
                }
            }
        }

        if (
            !skipAction ||
            Environment.isTest()
        ) {
            return next(action);
        }

        return next;
    };
}

export default actionBlockerMiddleware;
