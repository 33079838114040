//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes from '@components/PropTypes';
import Colors    from '@styles/colors.scss';

import styles from './styles.module.scss';

const propTypes = {
    color: PropTypes.string,
};

const SmallCircle = ({
    color = Colors.yellow,
}) => {
    const circleStyle = {
        backgroundColor: color,
    };

    return (
        <div
            className={styles.smallCircle}
            style={circleStyle}
        />
    );
};

SmallCircle.propTypes = propTypes;

export default SmallCircle;
