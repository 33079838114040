//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import Skeleton   from 'react-loading-skeleton';

import PropTypes        from '@components/PropTypes';
import SkeletonItemType from '@stateless/atomic/SkeletonItem/SkeletonItemType';
import Spacer           from '@stateless/atomic/Spacer';

import styles from './styles.module.scss';

const propTypes = {
    type: PropTypes.oneOfObjectValues(SkeletonItemType),
};

const SkeletonItem = ({
    type = SkeletonItemType.connectionItem,
}) => {
    const renderConnectionItem = () => {
        return (
            <div className={styles.listItemWrapper}>
                <Skeleton
                    height={20}
                    width={'100%'}
                />
                <Spacer height={20} />
                <Skeleton
                    height={20}
                    width={'80%'}
                />
            </div>
        );
    };
    const renderStopItem       = () => {
        return (
            <div
                className={classNames(
                    styles.listItemWrapper,
                    styles.listItemRow,
                )}
            >
                <Skeleton
                    height={30}
                    width={30}
                    circle={true}
                />
                <Spacer width={20} />
                <Skeleton
                    height={20}
                    width={200}
                />
            </div>
        );
    };

    switch (type) {
        case SkeletonItemType.connectionItem:
            return renderConnectionItem();
        case SkeletonItemType.stopItem:
            return renderStopItem();
        default:
            return null;
    }
};

SkeletonItem.propTypes = propTypes;

export default SkeletonItem;
